import React, { useState, useRef, useCallback, useEffect } from 'react';
import styles from '../atms.module.scss';
import { useSnackbar } from "notistack";
import { apiRequest } from "../../../api/services/Api";
import { CloseRemoveIcon } from '../../../svg-icons/icon-close-remove';
import { useTranslation } from "react-i18next";

interface ImportModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

interface PreviewRow {
  branchType: string;
  branchName: string;
  phones: string;
  address: string;
  schedule: string;
}

const ImportModal: React.FC<ImportModalProps> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [preview, setPreview] = useState<PreviewRow[]>([]);
  const [rawCsvData, setRawCsvData] = useState<string[][]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [columnWidths, setColumnWidths] = useState<{ [key: number]: number }>({});
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const processCSVContent = (text: string) => {
    // Split by lines and remove empty lines
    const lines = text.split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');

    // Parse each line, handling quoted values correctly
    const parsedLines = lines.map(line => {
      const values = [];
      let inQuotes = false;
      let currentValue = '';

      for (let i = 0; i < line.length; i++) {
        const char = line[i];
        const nextChar = line[i + 1];

        if (char === '"') {
          if (inQuotes && nextChar === '"') {
            // Handle escaped quotes
            currentValue += '"';
            i++; // Skip next quote
          } else {
            // Toggle quotes state
            inQuotes = !inQuotes;
          }
        } else if (char === ',' && !inQuotes) {
          // End of value
          values.push(currentValue.trim());
          currentValue = '';
        } else {
          currentValue += char;
        }
      }

      // Add the last value
      values.push(currentValue.trim());
      return values;
    });

    // Remove any BOM or special characters from the first value of the first row
    if (parsedLines.length > 0 && parsedLines[0].length > 0) {
      parsedLines[0][0] = parsedLines[0][0].replace(/^\uFEFF/, '');
    }

    // Ensure all rows have the same number of columns as the header
    const headerLength = parsedLines[0]?.length || 0;
    return parsedLines.map(row => {
      // If row has fewer columns than header, pad with empty strings
      while (row.length < headerLength) {
        row.push('');
      }
      // If row has more columns than header, trim excess columns
      return row.slice(0, headerLength);
    });
  };

  const handleFileContent = (text: string) => {
    const parsedLines = processCSVContent(text);
    if (parsedLines.length > 0) {
      setRawCsvData(parsedLines);
      setShowPreview(true);
    }
  };

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);

      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile && droppedFile.name.endsWith('.csv')) {
        setFile(droppedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result as string;
          handleFileContent(text);
        };
        reader.readAsText(droppedFile);
      }
    },
    []
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.name.endsWith('.csv')) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        handleFileContent(text);
      };
      reader.readAsText(selectedFile);
    }
  };

  const processFile = useCallback((file: File) => {
    if (!file.name.endsWith('.csv')) {
      enqueueSnackbar(t('Please_upload_csv'), { variant: 'error' });
      return;
    }

    if (file.size > 50 * 1024 * 1024) { // 50MB
      enqueueSnackbar(t('File_too_large'), { variant: 'error' });
      return;
    }

    setFile(file);
    setIsValidated(false);
    setPreview([]);
  }, [enqueueSnackbar, t]);

  const handleRemoveFile = () => {
    setFile(null);
    setPreview([]);
    setRawCsvData([]);
    setShowPreview(false);
    setIsValidated(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const formatTime = (time: string) => {
    if (!time) return '';
    const match = time.match(/(\d{1,2}):(\d{2})/);
    return match ? `${match[1]}:${match[2]}` : time;
  };

  const formatTimeForAPI = (timeStr: string) => {
    // Парсим время из строки, убираем кавычки и пробелы
    const cleanTime = timeStr.replace(/^"|"$/g, '').trim();
    const [hours, minutes] = cleanTime.split(':').map(num => num.padStart(2, '0'));
    
    // Создаем текущую дату в локальном часовом поясе
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    // Создаем дату с пользовательским временем в локальном часовом поясе
    const localDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
    
    // Возвращаем ISO строку (автоматически конвертируется в UTC)
    return localDate.toISOString();
  };

  const parseCSVRow = (row: string[]): PreviewRow | null => {
    if (!Array.isArray(row) || row.length < 15) return null;

    // Индексы колонок из файла CSV
    const NAME_INDEX = 2;        // Branch Name
    const TYPE_INDEX = 3;        // Branch Type
    const ADDRESS_INDEX = 7;     // Address
    const PHONES_INDEX = 8;      // Phones
    const WORKDAYS_INDEX = 9;    // Workdays
    const OPEN_TIME_INDEX = 10;  // Open time
    const CLOSE_TIME_INDEX = 11; // Close time

    // Очищаем значения от кавычек
    const cleanValue = (value: string) => value.replace(/^"|"$/g, '').trim();

    // Форматируем время работы в формате "9:00 - 19:00" и добавляем дни недели
    const workdays = cleanValue(row[WORKDAYS_INDEX] || '');
    const openTime = cleanValue(row[OPEN_TIME_INDEX] || '');
    const closeTime = cleanValue(row[CLOSE_TIME_INDEX] || '');
    const schedule = `${workdays} ${openTime} - ${closeTime}`;

    return {
      branchType: cleanValue(row[TYPE_INDEX] || ''),
      branchName: cleanValue(row[NAME_INDEX] || ''),
      phones: cleanValue(row[PHONES_INDEX] || ''),
      address: cleanValue(row[ADDRESS_INDEX] || ''),
      schedule: schedule
    };
  };

  const handleValidateFile = async () => {
    if (!file) return;

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      const { response } = await apiRequest(
        'post',
        '/admin/places/import',
        formData
      );

      if (response?.status === 200) {
        enqueueSnackbar(t('Locations_imported_successfully'), { variant: 'success' });
        onSuccess(); // Обновляем список
        onClose(); // Закрываем модалку
      } else {
        // Показываем сообщение об ошибке из ответа сервера или дефолтное сообщение
        const errorMessage = response?.data?.message || t('file_upload_error');
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    } catch (error: any) {
      console.error('Error uploading file:', error);
      const errorMessage = error?.response?.data?.message || t('file_upload_error');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    await handleValidateFile();
  };

  const handleZoneClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (rawCsvData.length > 0) {
      // Create temporary div for measuring
      const measureDiv = document.createElement('div');
      measureDiv.style.position = 'absolute';
      measureDiv.style.visibility = 'hidden';
      measureDiv.style.whiteSpace = 'nowrap';
      measureDiv.style.fontFamily = 'inherit';
      measureDiv.style.fontSize = '14px';
      measureDiv.style.fontWeight = '500';
      measureDiv.style.padding = '0 12px';
      document.body.appendChild(measureDiv);

      // Measure header widths
      const widths: { [key: number]: number } = {};
      rawCsvData[0].forEach((header, index) => {
        measureDiv.textContent = header;
        const width = measureDiv.offsetWidth;
        widths[index] = Math.max(width + 24, 80); // Add padding and set minimum width
      });

      document.body.removeChild(measureDiv);
      setColumnWidths(widths);
    }
  }, [rawCsvData]);

  const getColumnStyle = (index: number) => ({
    width: columnWidths[index] ? `${columnWidths[index]}px` : '140px',
    minWidth: columnWidths[index] ? `${columnWidths[index]}px` : '140px'
  });

  return (
    <div className={styles.modal_overlay} onClick={onClose}>
      <div
        className={`${styles.import_modal} ${showPreview && rawCsvData.length > 0 ? styles.with_preview : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.modal_header}>
          <div onClick={onClose} className={styles.close_button}>
            <CloseRemoveIcon />
          </div>

        </div>
        <div className={styles.modal_title}>
          <span>
            {t('Import_locations')}
          </span>
        </div>

        <div className={styles.modal_content}>
          {(!showPreview || !rawCsvData.length) && (
            <div
              className={`${styles.drop_zone} ${isDragging ? styles.dragging : ''}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleZoneClick}
            >
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <div className={styles.upload_icon}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.4998 26.9998L18.6148 29.1148L22.4998 25.2448V43.4998H25.4998V25.2448L29.3848 29.1148L31.4998 26.9998L23.9998 19.4998L16.4998 26.9998Z" fill="#A4A5B1" />
                  <path d="M35.2498 32.9998H34.4998V29.9998H35.2498C37.04 30.0715 38.7853 29.429 40.1018 28.2137C41.4183 26.9985 42.1982 25.3101 42.2698 23.5198C42.3414 21.7296 41.6989 19.9843 40.4837 18.6678C39.2684 17.3513 37.58 16.5715 35.7898 16.4998H34.4998L34.3498 15.2698C34.017 12.7445 32.7776 10.4263 30.8624 8.74692C28.9473 7.06758 26.4869 6.14167 23.9398 6.14167C21.3926 6.14167 18.9323 7.06758 17.0171 8.74692C15.102 10.4263 13.8626 12.7445 13.5298 15.2698L13.4998 16.4998H12.2098C10.4196 16.5715 8.73113 17.3513 7.5159 18.6678C6.30066 19.9843 5.65818 21.7296 5.72979 23.5198C5.8014 25.3101 6.58123 26.9985 7.89774 28.2137C9.21424 29.429 10.9596 30.0715 12.7498 29.9998H13.4998V32.9998H12.7498C10.3444 32.9846 8.02954 32.0807 6.25027 30.462C4.47099 28.8432 3.35291 26.6239 3.11098 24.2307C2.86905 21.8374 3.52035 19.4392 4.93967 17.4971C6.35898 15.555 8.44611 14.2062 10.7998 13.7098C11.4473 10.6896 13.111 7.98281 15.5133 6.04105C17.9155 4.09929 20.9109 3.04004 23.9998 3.04004C27.0886 3.04004 30.0841 4.09929 32.4863 6.04105C34.8885 7.98281 36.5522 10.6896 37.1998 13.7098C39.5535 14.2062 41.6406 15.555 43.0599 17.4971C44.4792 19.4392 45.1305 21.8374 44.8886 24.2307C44.6467 26.6239 43.5286 28.8432 41.7493 30.462C39.97 32.0807 37.6552 32.9846 35.2498 32.9998Z" fill="#A4A5B1" />
                </svg>
              </div>
              <div className={styles.upload_text}>
                <div>
                  {t('Drag_csv_here')} <span className={styles.click_here}>{t('Click_here')}</span> {t('To_select')}
                </div>
                <span className={styles.file_info}>{t('Max_size')}: 50 MB</span>
                <span className={styles.file_info}>{t('Allowed_format')}: .CSV</span>
              </div>
            </div>
          )}

          {file && (
            <div className={styles.preview_container}>
              <div className={styles.uploaded_file}>
                <div className={styles.uploaded_file_name_wrapper}>
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.8002 15.8998L4.6002 11.6998L3.2002 13.0998L8.8002 18.6998L20.8002 6.6998L19.4002 5.2998L8.8002 15.8998Z" fill="#3CBC8E" />
                    </svg>
                  </div>
                  <span>{file.name}</span>
                </div>

                <div className={styles.file_actions}>
                  <button onClick={handleRemoveFile} className={styles.remove_button}>
                    ×
                  </button>
                </div>
              </div>

              {showPreview && rawCsvData.length > 0 && (
                <div className={styles.csv_preview}>
                  <div className={styles.table_wrapper}>
                    <div className={styles.table}>
                      {rawCsvData.map((row, i) => (
                        <div key={i} className={`${styles.row} ${i === 0 ? styles.header_row : ''}`}>
                          <div className={styles.row_number}>
                            {i === 0 ? '' : i}
                          </div>
                          {row.map((cell, j) => (
                            <div
                              key={j}
                              className={styles.cell}
                              style={getColumnStyle(j)}
                            >
                              {cell}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {file && (
          <div className={styles.modal_footer}>
            <div
              onClick={!isLoading ? handleSubmit : undefined}
              className={`${styles.submit_button} ${isLoading ? styles.disabled : ''}`}
            >
              {isLoading ? t('Loading') : t('Import')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportModal;
