export const isBrowser = () => typeof window !== 'undefined' && typeof document !== 'undefined';

let savedScrollPosition: number | null = null;

export const disableBodyScroll = () => {
  if (!isBrowser() || !document.body) return;
  
  // Save current scroll position
  savedScrollPosition = window.scrollY;
  
  // Save current padding to prevent layout shift
  const currentPadding = parseInt(getComputedStyle(document.body).paddingRight) || 0;
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  
  // Apply styles to body
  document.body.style.position = 'fixed';
  document.body.style.top = `-${savedScrollPosition}px`;
  document.body.style.width = '100%';
  document.body.style.paddingRight = `${currentPadding + scrollbarWidth}px`;
};

export const enableBodyScroll = () => {
  if (!isBrowser() || !document.body) return;
  
  // Remove styles from body
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = '';
  document.body.style.paddingRight = '';
  
  // Restore scroll position
  if (savedScrollPosition !== null) {
    window.scrollTo(0, savedScrollPosition);
    savedScrollPosition = null;
  }
};

export const getScrollbarSize = () => {
  if (!isBrowser()) {
    return 0;
  }

  try {
    // Safety checks in specific order
    if (typeof window === 'undefined' || !window) {
      return 0;
    }

    if (typeof document === 'undefined' || !document) {
      return 0;
    }

    // Ensure we're fully loaded
    if (!document.body || !document.documentElement) {
      return 0;
    }

    const docElement = document.documentElement;
    const bodyElement = document.body;

    // Use body dimensions as fallback if documentElement is not ready
    const documentWidth = docElement.clientWidth || bodyElement.clientWidth;
    const windowWidth = window.innerWidth;

    return Math.max(0, Math.abs(windowWidth - documentWidth));
  } catch (e) {
    console.warn('Failed to calculate scrollbar size:', e);
    return 0;
  }
};
