import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.MainReducer;
};

export const getCustomerInfo = (state: RootState) => {
    return getMainReducerState(state).customerData || {};
};

export const getSideDrawerOpenState = (state: RootState) => {
    return getMainReducerState(state).showSideDrawer;
}

export const getSettingsState = (state: RootState) => {
    return getMainReducerState(state).settings;
}

export const getNotificationCount = (state: RootState) => {
    return getMainReducerState(state).notificationCount;
};

export const getPrevNotificationCount = (state: RootState) => {
    return getMainReducerState(state).prevNotificationCount;
};

export const getStartMarketHours = (state: RootState) => {
    return getMainReducerState(state).startMarketHours;
}

export const getMarketState = (state: RootState) => {
    return getMainReducerState(state).checkedStartMarket;
}

export const getPeriod = (state: RootState) => {
    return getMainReducerState(state).period;
}

export const getDateFrom = (state: RootState) => {
    return getMainReducerState(state).dateFrom;
}

export const getDateTo = (state: RootState) => {
    return getMainReducerState(state).dateTo;
}

export const getTerminalPageSize = (state: RootState) => {
    return getMainReducerState(state).terminalPage;
}

export const getMerchantPageSize = (state: RootState) => {
    return getMainReducerState(state).merchantPage;
}

export const getTransactionPageSize = (state: RootState) => {
    return getMainReducerState(state).transactionPage;
}

export const getDisputPageSize = (state: RootState) => {
    return getMainReducerState(state).disputPage;
}

export const getSellersPageSize = (state: RootState) => {
    return getMainReducerState(state).sellersPage;
}

export const getFlowData = (state: RootState) => {
    return getMainReducerState(state).flowData;
}
