import React from "react";

export const CreditCardIcon = () => {
  return (
    <svg
      width="60"
      height="71"
      viewBox="0 0 60 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.1668 11.834H11.8334C8.54966 11.834 5.94633 14.4669 5.94633 17.7507L5.91675 53.2506C5.91675 56.5344 8.54966 59.1673 11.8334 59.1673H59.1668C62.4505 59.1673 65.0834 56.5344 65.0834 53.2506V17.7507C65.0834 14.4669 62.4505 11.834 59.1668 11.834ZM56.2084 53.2506H14.7917C13.1647 53.2506 11.8334 51.9194 11.8334 50.2923V35.5007H59.1668V50.2923C59.1668 51.9194 57.8355 53.2506 56.2084 53.2506ZM59.1668 23.6673H11.8334V17.7507H59.1668V23.6673Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
