import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box } from "@mui/material";
import styles from "./delete-confirm-modal.module.scss";

interface DeleteConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  locationName?: string;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  onClose,
  onConfirm,
  locationName
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="delete-confirm-modal"
      className={styles.modal_overlay}
    >
      <Box className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modal_header}>

          <div className={styles.close_button} onClick={onClose}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.8749 7.13789C22.3874 6.65039 21.5999 6.65039 21.1124 7.13789L14.9999 13.2379L8.8874 7.12539C8.3999 6.63789 7.6124 6.63789 7.1249 7.12539C6.6374 7.61289 6.6374 8.40039 7.1249 8.88789L13.2374 15.0004L7.1249 21.1129C6.6374 21.6004 6.6374 22.3879 7.1249 22.8754C7.6124 23.3629 8.3999 23.3629 8.8874 22.8754L14.9999 16.7629L21.1124 22.8754C21.5999 23.3629 22.3874 23.3629 22.8749 22.8754C23.3624 22.3879 23.3624 21.6004 22.8749 21.1129L16.7624 15.0004L22.8749 8.88789C23.3499 8.41289 23.3499 7.61289 22.8749 7.13789Z" fill="#A1ABB2" />
            </svg>
          </div>
        </div>
        <div className={styles.modal_content}>
          <h2>{t("Delete_confirm_title")}</h2>
          <span>
            {locationName 
              ? t("Delete_location_confirm_message", { name: locationName })
              : t("Delete_confirm_message")}
          </span>
        </div>
        <div className={styles.modal_footer}>
          <button className={styles.delete_button} onClick={onConfirm}>
            {t("Delete_confirm")}
          </button>
          <button className={styles.cancel_button} onClick={onClose}>
            {t("Cancel")}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmModal;
