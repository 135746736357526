import { apiRequest } from "./Api";
import { User } from "../../pages/Users/interfaces/types";
import { enqueueSnackbar } from "notistack";
import { TFunction } from "i18next";

interface ApiResponse<T = void> {
  data?: T;
  success: boolean;
  error?: string;
  message?: string;
  shouldCloseModal?: boolean;
}

interface UsersData {
  users: User[];
  totalCount: number;
}

const handle401Error = (t: TFunction) => {
  enqueueSnackbar(t("Session_expired_redirecting"), { 
    variant: "error", 
    autoHideDuration: 3000 
  });
  
  // Use setTimeout to show the error message before refreshing
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const fetchUsers = async (
  roleFilter: string,
  searchText: string,
  currentPage: number,
  pageSize: number,
  t: TFunction
): Promise<ApiResponse<UsersData>> => {
  try {
    const newSearchText = searchText === "" ? "" : `=${searchText}`;
    const newRoleFilter = roleFilter === "" ? "" : `=${roleFilter.toUpperCase()}`;
    
    const { status, response } = await apiRequest(
      "get",
      `/admin/user?page=${currentPage + 1}&pageSize=${pageSize}&search${newSearchText}&role${newRoleFilter}`,
      null
    );

    if (status === 401) {
      handle401Error(t);
      return {
        success: false,
        error: t("Session_expired"),
        data: { users: [], totalCount: 0 }
      };
    }

    if (status === 200 && response?.data) {
      return {
        success: true,
        data: {
          users: response.data.data.map((user: any) => ({
            ...user,
            role: t(user.role === "ADMIN" ? "Admin" : "Employee")
          })) || [],
          totalCount: response.data.pagination?.totalCount || response.data.totalCount || 0
        },
        message: t("Users_loaded_successfully")
      };
    }
    
    enqueueSnackbar(t("Error_loading_users"), { variant: "error" });
    return {
      success: false,
      error: t("Error_loading_users"),
      data: { users: [], totalCount: 0 }
    };
  } catch (error) {
    enqueueSnackbar(t("Error_loading_users"), { variant: "error" });
    return {
      success: false,
      error: t("Error_loading_users"),
      data: { users: [], totalCount: 0 }
    };
  }
};

export const addUser = async (userData: any, t: TFunction): Promise<ApiResponse> => {
  try {
    const { status, response } = await apiRequest("post", "/admin/user", userData);

    if (status === 401) {
      handle401Error(t);
      return {
        success: false,
        error: t("Session_expired")
      };
    }

    if (status === 200) {
      const result = {
        success: true,
        message: t("User_added_successfully"),
        shouldCloseModal: true
      };
      
      setTimeout(() => {
        enqueueSnackbar(t("User_added_successfully"), { 
          variant: "success",
          autoHideDuration: 2000
        });
      }, 100);

      return result;
    }

    enqueueSnackbar(t("Error_adding_user"), { variant: "error" });
    return {
      success: false,
      error: t("Error_adding_user")
    };
  } catch (error) {
    enqueueSnackbar(t("Error_adding_user"), { variant: "error" });
    return {
      success: false,
      error: t("Error_adding_user")
    };
  }
};

export const changePassword = async (
  userId: number,
  newPassword: string,
  t: TFunction
): Promise<ApiResponse> => {
  try {
    const { status, response } = await apiRequest("patch", `/admin/user/${userId}`, { newPassword });

    if (status === 401) {
      handle401Error(t);
      return {
        success: false,
        error: t("Session_expired")
      };
    }

    if (status === 200 && response?.data) {
      enqueueSnackbar(t("Password_changed_successfully"), { variant: "success" });
      return {
        success: true,
        message: t("Password_changed_successfully")
      };
    }

    enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
    return {
      success: false,
      error: t("Error_changing_password")
    };
  } catch (error) {
    enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
    return {
      success: false,
      error: t("Error_changing_password")
    };
  }
};
