import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import moment from "moment";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import { CreditCardIcon } from "../../svg-icons/icon-credit-card";
import { AccountBalanceIcon } from "../../svg-icons/icon-balance";
import { PercentIcon } from "../../svg-icons/icon-percent";
import { NavigatorBeforeIcon } from "../../svg-icons/icon-navigator-before";
import { SavingIcon } from "../../svg-icons/icon-saving";
import styles from "./client-details.module.scss";
import { useTranslation } from "react-i18next";
import {
  fetchClientDetails,
  Client,
  Account,
  Deposit,
  Credit,
  Card,
} from "../../api/services/Clients";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import Spinner from "../../infrastructure/components/Spinner/Spinner";
import Pagination from "../../infrastructure/components/Pagination/Pagination";

interface InfoField {
  label: string;
  value: string;
  isStatus?: boolean;
}

const ClientDetails: React.FC = () => {
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [clientData, setClientData] = useState<Client | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedProductType, setSelectedProductType] = useState<
    keyof Client["activeProducts"] | null
  >(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentProductPage, setCurrentProductPage] = useState<number>(0);
  const [pageSize] = useState<number>(5);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      if (!clientId) return;
      setIsFetching(true);
      try {
        const response = await fetchClientDetails(clientId, t);
        if (response.result === "SUCCESS") {
          const clientDataFormatted: Client = {
            personalInfo: response.data.personalInfo,
            activeProducts: response.data.activeProducts,
            sessions: response.data.sessions,
            status: "ACTIVE",
            createdAt: new Date().toISOString(),
          };
          setClientData(clientDataFormatted);
        } else {
          enqueueSnackbar(t("Something_went_wrong"), {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(t("Something_went_wrong"), {
          variant: "error",
        });
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [clientId, t]);

  useEffect(() => {
    setTotalCount(clientData?.sessions?.length || 0);
  }, [clientData?.sessions]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setSelectedProductType(null);
  };

  const handleShowDetails = (productType: keyof Client["activeProducts"]) => {
    setSelectedProductType(productType);
  };

  const getSessionDeviceInfo = (session: any) => {
    const deviceInfo = session.deviceInfo;
    if (!deviceInfo) return "-";

    const platform = deviceInfo.platform || "-";
    const version = deviceInfo.version || "";
    const manufacturer = deviceInfo.manufacturer || "";
    const model = deviceInfo.model || "";

    return `${platform} ${version} ${manufacturer} ${model}`;
  };

  const getSessionLocation = (session: any) => {
    const geoLocation = session.geoLocation;
    if (!geoLocation) return "-";

    const latitude = geoLocation.latitude || "-";
    const longitude = geoLocation.longitude || "-";

    return `${latitude}, ${longitude}`;
  };

  const getSessionIp = (session: any) => {
    return session.ipAddress || "-";
  };

  const getSessionEndTime = (session: any) => {
    return session.sessionEnd
      ? dayjs(session.sessionEnd).format("DD-MM-YYYY HH:mm")
      : "-";
  };

  const getSessionsTableData = () => {
    if (!clientData?.sessions) return [];

    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;

    // Sort sessions: active sessions first (no end date), then by end date descending
    const sortedSessions = [...clientData.sessions].sort((a, b) => {
      // If either session has no end date (active session), it should come first
      if (!a.sessionEnd && !b.sessionEnd) return 0;
      if (!a.sessionEnd) return -1;
      if (!b.sessionEnd) return 1;
      
      // Sort by end date descending (newer dates first)
      return new Date(b.sessionEnd).getTime() - new Date(a.sessionEnd).getTime();
    });

    return sortedSessions.slice(startIndex, endIndex).map((session) => ({
      id: session.id,
      sessionStart: moment(session.sessionStart).format("DD.MM.YYYY HH:mm:ss"),
      sessionEnd: getSessionEndTime(session),
      ipAddress: getSessionIp(session),
      geoLocation: getSessionLocation(session),
      deviceInfo: getSessionDeviceInfo(session),
      appVersion: session.appVersion || "-",
    }));
  };

  const sessionsColumns = [
    {
      field: "sessionStart",
      headerName: t("Session_start"),
      width: 190,
      sortable: false,
    },
    {
      field: "sessionEnd",
      headerName: t("Session_end"),
      width: 180,
      sortable: false,
    },
    {
      field: "ipAddress",
      headerName: t("IP_address"),
      width: 150,
      sortable: false,
    },
    {
      field: "geoLocation",
      headerName: t("Geo_location"),
      width: 230,
      sortable: false,
    },
    {
      field: "deviceInfo",
      headerName: t("Device_info"),
      width: 150,
      sortable: false,
    },
    {
      field: "appVersion",
      headerName: t("App_version"),
      width: 200,
      sortable: false,
    },
  ];

  const renderClientInfo = () => {
    if (!clientData) return null;

    const mainFields: InfoField[] = [
      { label: t("Status"), value: t(clientData.status), isStatus: true },
      { label: t("IDNP"), value: clientData.personalInfo.idnp || "-" },
      { label: t("Birth_date"), value: clientData.personalInfo.dob || "-" },
      {
        label: t("Risk_level"),
        value: clientData.personalInfo.riskLevel || "-",
      },
    ];

    const additionalFields: InfoField[] = [
      { label: t("Phone"), value: clientData.personalInfo.phone || "-" },
      { label: t("Email"), value: clientData.personalInfo.email || "-" },
      { label: t("Address"), value: clientData.personalInfo.address || "-" },
    ];

    const fieldsToShow = isExpanded
      ? [...mainFields, ...additionalFields]
      : mainFields;

    return (
      <div className={styles.clientInfoContainer}>
        <div className={styles.clientInfoHeader}>
          <h2>{t("General_data")}</h2>
          <div className={styles.registrationDate}>
            {t("Registration_date")}:{" "}
            {dayjs(clientData.createdAt).format("DD-MM-YYYY HH:mm")}
          </div>
        </div>
        <div className={styles.clientInfoContent}>
          {fieldsToShow.map((field, index) => (
            <div key={index} className={styles.infoRow}>
              <div className={styles.label}>{field.label}</div>
              <div
                className={`${styles.value} ${field.isStatus ? styles[clientData.status.toLowerCase()] : ""}`}
              >
                {field.value}
              </div>
            </div>
          ))}
        </div>
        <button
          className={styles.expandButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          { isExpanded ? t("Show_less") : t("Show_more")}
          <span
            className={`${styles.arrow} ${isExpanded ? styles.up : ""}`}
          ></span>
        </button>
      </div>
    );
  };

  const renderProducts = () => {
    if (!clientData) {
      if (isFetching) {
        return (
          <div className={styles.spinnerContainer}>
            <Spinner size={30} color="#E5891D" />
          </div>
        );
      }
      return null;
    }
    const { accounts, deposits, credits, cards } = clientData.activeProducts;
    const productCounts = [
      {
        name: t("Accounts"),
        count: accounts.length,
        type: "accounts",
        icon: <AccountBalanceIcon />,
      },
      {
        name: t("Deposits"),
        count: deposits.length,
        type: "deposits",
        icon: <SavingIcon />,
      },
      {
        name: t("Credits"),
        count: credits.length,
        type: "credits",
        icon: <PercentIcon />,
      },
      {
        name: t("Cards"),
        count: cards.length,
        type: "cards",
        icon: <CreditCardIcon />,
      },
    ];

    if (selectedProductType) {
      return renderProductDetails(selectedProductType);
    }

    return (
      <div className={styles.products_container}>
        {productCounts.map((product, index) => (
          <div key={index} className={styles.product_card}>
            <div className={styles.top_wrapper_card}>
              <div className={styles.top_block_card}>
                <p>{product.count}</p>
                <h3>{product.name}</h3>
              </div>
              <div className={styles.product_icon}>{product.icon}</div>
            </div>

            <button
              className={styles.details_button}
              onClick={() =>
                handleShowDetails(
                  product.type as keyof Client["activeProducts"]
                )
              }
              disabled={product.count === 0}
            >
              {t("View_Details")} →
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderProductDetails = (
    productType: keyof Client["activeProducts"]
  ) => {
    if (!clientData) return null;

    const products = clientData.activeProducts[productType];

    if (products.length === 0) {
      return <p>{t("No_Products_Available")}</p>;
    }

    const columns = getColumnsForProductType(productType);

    return (
      <div>
        <div
          className={styles.back_button}
          onClick={() => setSelectedProductType(null)}
        >
          <NavigatorBeforeIcon /> {t("Back")}
        </div>
        <DataTableComponent
          columns={columns}
          rows={getProductsTableData(products)}
          checkboxSelection={false}
          isFetching={false}
          idChangeValue={"id"}
          className={styles.data_table}
        />
        <Pagination
          onClick={productPaginationClick}
          total={products.length}
          pageIndex={currentProductPage}
          pageSize={pageSize}
          hidePageSize
        />
      </div>
    );
  };

  const getProductsTableData = (
    products: Array<Account | Deposit | Credit | Card>
  ) => {
    const startIndex = currentProductPage * pageSize;
    const endIndex = startIndex + pageSize;

    return products.slice(startIndex, endIndex).map((product) => ({
      id: product.id,
      alias: product.alias || "-",
      ...("accountName" in product && { accountName: product.accountName }),
      ...("accountNumber" in product && {
        accountNumber: product.accountNumber,
      }),
      ...("startDate" in product && {
        startDate: product.startDate
          ? dayjs(product.startDate).format("DD-MM-YYYY HH:mm")
          : "-",
      }),
      ...("endDate" in product && {
        endDate: product.endDate
          ? dayjs(product.endDate).format("DD-MM-YYYY HH:mm")
          : "-",
      }),
      ...("conditions" in product && { conditions: product.conditions || "-" }),
      ...("amount" in product && { amount: product.amount }),
      ...("cardNumber" in product && { cardNumber: product.cardNumber }),
      ...("expiryDate" in product && {
        expiryDate: product.expiryDate
          ? dayjs(product.expiryDate).format("DD-MM-YYYY")
          : "-",
      }),
      currency: product.currency,
      ...("balance" in product && { balance: product.balance }),
    }));
  };

  const getColumnsForProductType = (
    productType: keyof Client["activeProducts"]
  ) => {
    switch (productType) {
      case "accounts":
        return [
          { field: "id", headerName: t("ID"), flex: 0.5 },
          { field: "alias", headerName: t("Alias"), flex: 0.8 },
          { field: "accountName", headerName: t("Account_Name"), flex: 1 },
          { field: "accountNumber", headerName: t("Account_Number"), flex: 1 },
          { field: "currency", headerName: t("Currency"), flex: 0.5 },
          // { field: "balance", headerName: t("Balance"), flex: 0.5 },
        ];
      case "deposits":
        return [
          { field: "id", headerName: t("ID"), flex: 0.5 },
          { field: "alias", headerName: t("Alias"), flex: 1 },
          { field: "startDate", headerName: t("Start_Date"), flex: 1 },
          { field: "endDate", headerName: t("End_Date"), flex: 1 },
          { field: "conditions", headerName: t("Conditions"), flex: 1 },
          { field: "amount", headerName: t("Amount"), flex: 1 },
          { field: "currency", headerName: t("Currency"), flex: 0.5 },
        ];
      case "credits":
        return [
          { field: "id", headerName: t("ID"), flex: 0.5 },
          { field: "alias", headerName: t("Alias"), flex: 1 },
          { field: "startDate", headerName: t("Start_Date"), flex: 1 },
          { field: "endDate", headerName: t("End_Date"), flex: 1 },
          { field: "amount", headerName: t("Amount"), flex: 1 },
          { field: "conditions", headerName: t("Conditions"), flex: 1 },
          { field: "currency", headerName: t("Currency"), flex: 0.5 },
        ];
      case "cards":
        return [
          { field: "id", headerName: t("ID"), flex: 0.5 },
          { field: "alias", headerName: t("Alias"), flex: 1 },
          { field: "cardNumber", headerName: t("Card_Number"), flex: 1 },
          { field: "expiryDate", headerName: t("Expiry_Date"), flex: 1 },
          // { field: "balance", headerName: t("Balance"), flex: 1 },
          { field: "currency", headerName: t("Currency"), flex: 0.5 },
        ];
      default:
        return [];
    }
  };

  const renderSessions = () => {
    if (!clientData) return null;

    return (
      <div className={styles.tabContent}>
        <div className={styles.sessionsTableContainer}>
          <div className={styles.sessionsTable}>
            <DataTableComponent
              rows={getSessionsTableData()}
              columns={sessionsColumns}
              isFetching={isFetching}
              idChangeValue="id"
              checkboxSelection={false}
              className={styles.data_table}
            />
          </div>
          <Pagination
            onClick={paginationClick}
            total={totalCount}
            pageIndex={currentPage}
            pageSize={pageSize}
            hidePageSize
          />
        </div>
      </div>
    );
  };

  const paginationClick = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const productPaginationClick = (newPage: number) => {
    setCurrentProductPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    // Since we have a fixed pageSize of 5, we don't need to implement this
    // but we keep it to match the Pagination component interface
  };

  const breadcrumbs = {
    step1Label: <span className={styles.breadcrumb_link}>{t("Clients")}</span>,
    step1Url: "/app/clients",
    step2Label: clientData?.personalInfo.fullName || t("Client"),
    separator: <span className={styles.breadcrumb_separator}>&gt;</span>,
  };

  return (
    <AppMainLayout
      breadcrumbs={breadcrumbs}
      navBarTitle={`${t("Client")} ${clientData?.personalInfo.fullName || `#${clientId}`}`}
      noBackground
    >
      {renderClientInfo()}
      <div className={styles.custom_tabs_container}>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab_button} ${
              activeTab === 0 ? styles.active_tab : ""
            }`}
            onClick={() => handleTabChange(0)}
          >
            {t("Products")}
          </div>
          <div
            className={`${styles.second_tab_button} ${activeTab === 1 ? styles.active_tab : ""}`}
            onClick={() => handleTabChange(1)}
          >
            {t("User_Sessions")}
          </div>
        </div>
        <div className={styles.tab_content}>
          {activeTab === 0 ? renderProducts() : renderSessions()}
        </div>
      </div>
    </AppMainLayout>
  );
};

export default ClientDetails;
