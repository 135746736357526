import React from "react";

export const LocationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 1.80103C6.49992 1.80103 3.33325 4.48436 3.33325 8.63436C3.33325 11.2844 5.37492 14.401 9.44992 17.9927C9.76659 18.2677 10.2416 18.2677 10.5583 17.9927C14.6249 14.401 16.6666 11.2844 16.6666 8.63436C16.6666 4.48436 13.4999 1.80103 9.99992 1.80103ZM9.99992 10.1344C9.08325 10.1344 8.33325 9.38436 8.33325 8.46769C8.33325 7.55102 9.08325 6.80102 9.99992 6.80102C10.9166 6.80102 11.6666 7.55102 11.6666 8.46769C11.6666 9.38436 10.9166 10.1344 9.99992 10.1344Z"
        fill="#A4A5B1"
      />
    </svg>
  );
};
