import React from "react";

export const AccountBalanceIcon = () => {
  return (
    <svg
      width="61"
      height="71"
      viewBox="0 0 61 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="account_balance">
        <path
          id="Vector"
          d="M14.112 35.1191V46.9524C14.112 49.4078 16.0941 51.3899 18.5495 51.3899C21.0049 51.3899 22.987 49.4078 22.987 46.9524V35.1191C22.987 32.6637 21.0049 30.6816 18.5495 30.6816C16.0941 30.6816 14.112 32.6637 14.112 35.1191ZM31.862 35.1191V46.9524C31.862 49.4078 33.8441 51.3899 36.2995 51.3899C38.7549 51.3899 40.737 49.4078 40.737 46.9524V35.1191C40.737 32.6637 38.7549 30.6816 36.2995 30.6816C33.8441 30.6816 31.862 32.6637 31.862 35.1191ZM12.6328 66.1816H59.9661C62.4216 66.1816 64.4036 64.1995 64.4036 61.7441C64.4036 59.2887 62.4216 57.3066 59.9661 57.3066H12.6328C10.1774 57.3066 8.19531 59.2887 8.19531 61.7441C8.19531 64.1995 10.1774 66.1816 12.6328 66.1816ZM49.612 35.1191V46.9524C49.612 49.4078 51.5941 51.3899 54.0495 51.3899C56.5049 51.3899 58.487 49.4078 58.487 46.9524V35.1191C58.487 32.6637 56.5049 30.6816 54.0495 30.6816C51.5941 30.6816 49.612 32.6637 49.612 35.1191ZM33.5482 5.50617L10.1774 17.8128C8.96448 18.4341 8.19531 19.7062 8.19531 21.067C8.19531 23.1083 9.85198 24.7649 11.8932 24.7649H60.7353C62.747 24.7649 64.4036 23.1083 64.4036 21.067C64.4036 19.7062 63.6345 18.4341 62.4216 17.8128L39.0507 5.50617C37.3349 4.58909 35.2641 4.58909 33.5482 5.50617Z"
          fill="#E6E6E6"
        />
      </g>
    </svg>
  );
};
