import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import styles from "./atm-details.module.scss";
import { useSnackbar } from "notistack";
import {
  TextField,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Checkbox
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { apiRequest } from "../../api/services/Api";
import Spinner from "../../infrastructure/components/Spinner/Spinner";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import DeleteConfirmModal from "../ATMS/components/DeleteConfirmModal";

interface Schedule {
  openTime?: string;
  closedTime?: string;
  startLunchTime?: string;
  endLunchTime?: string;
  flexibleLunchTime?: boolean;
}

interface WeekSchedule {
  [key: string]: {
    isActive: boolean;
    schedule: Schedule | null;
  };
}

interface ATMData {
  id?: string;
  name: string;
  contacts: string[];
  type: string;
  address: string;
  region: string;
  city: string;
  geolocation: {
    latitude: number;
    longitude: number;
  };
  schedule?: {
    nonStop?: boolean;
    [key: string]: Schedule | boolean | undefined;
  };
  is24h: boolean;
  is_open: boolean;
}

type DayType = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const ATMDetails: React.FC = () => {
  const { atmId } = useParams<{ atmId: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(true);
  const [atmData, setAtmData] = useState<ATMData | null>(null);
  const [weekSchedule, setWeekSchedule] = useState<WeekSchedule>({});
  const [is24h, setIs24h] = useState(false);
  const [expandedDays, setExpandedDays] = useState<{ [key: string]: boolean }>({});
  const [timeInputs, setTimeInputs] = useState<{ [key: string]: string }>({});
  const [coordinatesInput, setCoordinatesInput] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [originalData, setOriginalData] = useState<{
    weekSchedule: WeekSchedule;
    is24h: boolean;
    is_open: boolean;
    address: string;
    region: string;
    geolocation: {
      latitude: number;
      longitude: number;
    };
    contacts: string[];
  } | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const days = useMemo(() => [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ] as const, []);

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true);
      setIsFormChanged(false);
      setIsInitialized(false);
      setOriginalData(null);
      setAtmData(null);

      const { response } = await apiRequest(
        "GET",
        `/admin/places/${atmId}`,
        null
      );

      if (response?.data?.result === "SUCCESS") {
        const data = response.data.data;
        const contacts = Array.isArray(data.contacts) 
          ? data.contacts.flat().filter(Boolean)
          : [];

        const schedule: WeekSchedule = {};
        days.forEach(day => {
          const daySchedule = data.schedule?.[day] as Schedule | undefined;
          schedule[day] = {
            isActive: !!daySchedule,
            schedule: daySchedule || null
          };
        });

        const originalDataCopy = {
          weekSchedule: { ...schedule },
          is24h: !!data.schedule?.nonStop,
          is_open: data.is_open,
          address: data.address,
          region: data.region,
          geolocation: { ...data.geolocation },
          contacts: [...contacts]
        };

        setAtmData({ ...data, contacts });
        setWeekSchedule(schedule);
        setIs24h(!!data.schedule?.nonStop);
        setOriginalData(originalDataCopy);
        setIsInitialized(true);
        setIsFormChanged(false);
        if (data.geolocation.latitude === 0 && data.geolocation.longitude === 0) {
          setCoordinatesInput('');
        } else {
          setCoordinatesInput(`${data.geolocation.latitude}, ${data.geolocation.longitude}`);
        }
      } else {
        throw new Error("Failed to load ATM details");
      }
    } catch (error) {
      console.error("Error loading ATM details:", error);
      enqueueSnackbar("Eroare la încărcarea datelor", { variant: "error" });
    } finally {
      setIsFetching(false);
    }
  }, [atmId, enqueueSnackbar, days]);

  useEffect(() => {
    setIsFormChanged(false);
    setIsInitialized(false);
    fetchData();
  }, [fetchData]);



  const compareObjects = useCallback((obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 !== 'object') return obj1 === obj2;
    if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;
    
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});
    if (keys1.length !== keys2.length) return false;
    
    return keys1.every(key => compareObjects(obj1[key], obj2[key]));
  }, []);



  const formatTime = (time: string | undefined | boolean) => {
    if (!time || typeof time === 'boolean') return "";
    const timeStr = time.split("T")[1];
    return timeStr ? timeStr.substring(0, 5) : "";
  };

  const parseTime = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(":");
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const handleCopyCoordinates = () => {
    if (atmData?.geolocation.latitude && atmData?.geolocation.longitude) {
      const coords = `${atmData.geolocation.latitude}, ${atmData.geolocation.longitude}`;
      navigator.clipboard.writeText(coords);
      enqueueSnackbar("Coordonate copiate", { variant: "success" });
    }
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    if (!atmData) return;
    setAtmData({
      ...atmData,
      is_open: event.target.value === 'open'
    });
  };

  const handleDayToggle = (day: DayType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const isChecked = event.target.checked;

    setExpandedDays(prev => ({
      ...prev,
      [day]: isChecked
    }));

    setWeekSchedule(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        isActive: isChecked,
        schedule: {
          openTime: "",
          closedTime: "",
          startLunchTime: "",
          endLunchTime: "",
          flexibleLunchTime: false
        }
      }
    }));
    setIsFormChanged(true);
  };

  const handleAccordionChange = (day: DayType) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedDays(prev => ({ ...prev, [day]: isExpanded }));
  };

  const handleTimeInput = (value: string, oldValue: string, selectionStart: number | null) => {
    const numbers = value.replace(/\D/g, '');
    const limited = numbers.slice(0, 4);

    if (limited.length >= 2) {
      const hours = limited.slice(0, 2);
      const minutes = limited.slice(2);
      const hoursNum = parseInt(hours);

      if (hoursNum > 23) return { value: "23:00", cursorPosition: selectionStart };

      if (minutes) {
        const minutesNum = parseInt(minutes);
        if (minutesNum > 59) return { value: `${hours}:59`, cursorPosition: selectionStart };
        return { value: `${hours}:${minutes}`, cursorPosition: selectionStart };
      }

      if (oldValue && oldValue.includes(':') && selectionStart === 3) {
        return { value: `${hours}:`, cursorPosition: 3 };
      }

      return { value: `${hours}:`, cursorPosition: selectionStart };
    }

    return { value: limited, cursorPosition: selectionStart };
  };

  const handleTimeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^\d$/.test(e.key) && 
      !['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const handleTimeChange = (day: DayType, field: keyof Schedule) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputKey = `${day}-${field}`;

    setTimeInputs(prev => ({
      ...prev,
      [inputKey]: newValue
    }));

    if (/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(newValue)) {
      setWeekSchedule(prev => {
        const currentDay = prev[day];
        if (!currentDay?.schedule) return prev;

        return {
          ...prev,
          [day]: {
            ...currentDay,
            schedule: {
              ...currentDay.schedule,
              [field]: parseTime(newValue)
            }
          }
        };
      });
      setIsFormChanged(true);
    }
  };

  const handleTimeBlur = (day: DayType, field: keyof Schedule) => () => {
    const inputKey = `${day}-${field}`;
    const currentValue = timeInputs[inputKey];

    if (!currentValue) {
      const originalTime = weekSchedule[day]?.schedule?.[field];
      if (typeof originalTime === 'boolean') return;

      setTimeInputs(prev => ({
        ...prev,
        [inputKey]: formatTime(originalTime)
      }));
      return;
    }

    let formattedTime = currentValue;

    const parts = currentValue.split(':');
    if (parts.length === 2) {
      const hours = parts[0].padStart(2, '0');
      const minutes = parts[1].padStart(2, '0');
      formattedTime = `${hours}:${minutes}`;
    }

    setTimeInputs(prev => ({
      ...prev,
      [inputKey]: formattedTime
    }));
  };

  const handleFlexibleChange = (day: DayType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setWeekSchedule(prev => {
      const currentDay = prev[day];
      if (!currentDay?.schedule) return prev;

      return {
        ...prev,
        [day]: {
          ...currentDay,
          schedule: {
            ...currentDay.schedule,
            flexibleLunchTime: isChecked,
            startLunchTime: isChecked ? "" : currentDay.schedule.startLunchTime,
            endLunchTime: isChecked ? "" : currentDay.schedule.endLunchTime
          }
        }
      };
    });

    if (isChecked) {
      const startLunchKey = `${day}-startLunchTime`;
      const endLunchKey = `${day}-endLunchTime`;
      setTimeInputs(prev => ({
        ...prev,
        [startLunchKey]: "",
        [endLunchKey]: ""
      }));
    }
    setIsFormChanged(true);
  };

  const getInputValue = (day: DayType, field: keyof Schedule, schedule: Schedule | null) => {
    const inputKey = `${day}-${field}`;
    const value = schedule?.[field];
    if (typeof value === 'boolean') return "";

    return timeInputs[inputKey] !== undefined ? timeInputs[inputKey] : formatTime(value);
  };

  const renderDaySchedule = (day: DayType, label: string) => {
    if (!atmData?.schedule || is24h) return null;
    const dayState = weekSchedule[day];
    if (!dayState) {
      // Initialize the day state if it doesn't exist
      setWeekSchedule(prev => ({
        ...prev,
        [day]: {
          isActive: false,
          schedule: {
            openTime: "",
            closedTime: "",
            startLunchTime: "",
            endLunchTime: "",
            flexibleLunchTime: false
          }
        }
      }));
      return null;
    }

    const { isActive, schedule } = dayState;
    const isDayActive = weekSchedule[day]?.isActive ?? false;
    if (!schedule) {
      setWeekSchedule(prev => ({
        ...prev,
        [day]: {
          ...prev[day],
          schedule: {
            openTime: "",
            closedTime: "",
            startLunchTime: "",
            endLunchTime: "",
            flexibleLunchTime: false
          }
        }
      }));
      return null;
    }

    return (
      <Accordion
        expanded={weekSchedule[day]?.isActive && (expandedDays[day] ?? false)}
        onChange={handleAccordionChange(day)}
        className={classNames(styles.dayRow, { [styles.inactive]: !weekSchedule[day]?.isActive })}
        sx={{
          borderRadius: '12px !important',
          border: '0.5px solid #E6E6E6',
          boxShadow: 'none',
          '&.MuiPaper-root.Mui-expanded': {
            background: '#F7F7F5'
          },
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          className={styles.dayHeader}
          onClick={(e) => {
            e.stopPropagation();
            if (!weekSchedule[day]?.isActive) {
              e.preventDefault();
              return;
            }
          }}
          sx={{
            minHeight: '48px !important',
            height: '48px',
            '& .MuiAccordionSummary-content': {
              margin: '0 !important',
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={handleDayToggle(day)}
                onClick={(e) => e.stopPropagation()}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 107, 0, 0.08)'
                    }
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#ff6b00'
                  }
                }}
              />
            }
            label={label}
            className={styles.dayToggle}
            sx={{
              margin: 0,
              '& .MuiFormControlLabel-label': {
                marginLeft: '8px'
              }
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.scheduleInputs}>
            <div className={styles.timeRow}>
              <span className={styles.timeLabel}>{t("work_hours")}</span>
              <TextField
                value={getInputValue(day, 'openTime', schedule)}
                onChange={(e) => {
                  const inputKey = `${day}-openTime`;
                  const oldValue = timeInputs[inputKey] || '';
                  const result = handleTimeInput(e.target.value, oldValue, e.target.selectionStart);
                  
                  setTimeInputs(prev => ({
                    ...prev,
                    [inputKey]: result.value
                  }));
                  
                  if (result.value.length === 5) {
                    handleTimeChange(day, 'openTime')({ target: { value: result.value } } as React.ChangeEvent<HTMLInputElement>);
                  }
                  
                  if (result.cursorPosition !== null) {
                    setTimeout(() => {
                      e.target.setSelectionRange(result.cursorPosition!, result.cursorPosition!);
                    }, 0);
                  }
                }}
                onKeyDown={handleTimeKeyDown}
                size="small"
                variant="outlined"
                className={styles.timeInput}
                placeholder="00:00"
                inputProps={{
                  maxLength: 5
                }}
              />
              <span className={styles.timeSeparator}>{t("to")}</span>
              <TextField
                value={getInputValue(day, 'closedTime', schedule)}
                onChange={(e) => {
                  const inputKey = `${day}-closedTime`;
                  const oldValue = timeInputs[inputKey] || '';
                  const result = handleTimeInput(e.target.value, oldValue, e.target.selectionStart);
                  
                  setTimeInputs(prev => ({
                    ...prev,
                    [inputKey]: result.value
                  }));
                  
                  if (result.value.length === 5) {
                    handleTimeChange(day, 'closedTime')({ target: { value: result.value } } as React.ChangeEvent<HTMLInputElement>);
                  }
                  
                  if (result.cursorPosition !== null) {
                    setTimeout(() => {
                      e.target.setSelectionRange(result.cursorPosition!, result.cursorPosition!);
                    }, 0);
                  }
                }}
                onKeyDown={handleTimeKeyDown}
                size="small"
                variant="outlined"
                className={styles.timeInput}
                placeholder="00:00"
                inputProps={{
                  maxLength: 5
                }}
              />
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={schedule?.flexibleLunchTime || false}
                  onChange={handleFlexibleChange(day)}
                  color="primary"
                />
              }
              label={t("flexible_lunch")}
              className={styles.flexibleCheckbox}
            />

            <div className={styles.timeRow}>
              <span className={styles.timeLabel}>{t("lunch_hours")}</span>
              <TextField
                value={getInputValue(day, 'startLunchTime', schedule)}
                onChange={(e) => {
                  const inputKey = `${day}-startLunchTime`;
                  const oldValue = timeInputs[inputKey] || '';
                  const result = handleTimeInput(e.target.value, oldValue, e.target.selectionStart);
                  
                  setTimeInputs(prev => ({
                    ...prev,
                    [inputKey]: result.value
                  }));
                  
                  if (result.value.length === 5) {
                    handleTimeChange(day, 'startLunchTime')({ target: { value: result.value } } as React.ChangeEvent<HTMLInputElement>);
                  }
                  
                  if (result.cursorPosition !== null) {
                    setTimeout(() => {
                      e.target.setSelectionRange(result.cursorPosition!, result.cursorPosition!);
                    }, 0);
                  }
                }}
                onKeyDown={handleTimeKeyDown}
                size="small"
                variant="outlined"
                className={styles.timeInput}
                placeholder="00:00"
                disabled={schedule.flexibleLunchTime}
                inputProps={{
                  maxLength: 5
                }}
              />
              <span className={styles.timeSeparator}>{t("to")}</span>
              <TextField
                value={getInputValue(day, 'endLunchTime', schedule)}
                onChange={(e) => {
                  const inputKey = `${day}-endLunchTime`;
                  const oldValue = timeInputs[inputKey] || '';
                  const result = handleTimeInput(e.target.value, oldValue, e.target.selectionStart);
                  
                  setTimeInputs(prev => ({
                    ...prev,
                    [inputKey]: result.value
                  }));
                  
                  if (result.value.length === 5) {
                    handleTimeChange(day, 'endLunchTime')({ target: { value: result.value } } as React.ChangeEvent<HTMLInputElement>);
                  }
                  
                  if (result.cursorPosition !== null) {
                    setTimeout(() => {
                      e.target.setSelectionRange(result.cursorPosition!, result.cursorPosition!);
                    }, 0);
                  }
                }}
                onKeyDown={handleTimeKeyDown}
                size="small"
                variant="outlined"
                className={styles.timeInput}
                placeholder="00:00"
                disabled={schedule.flexibleLunchTime}
                inputProps={{
                  maxLength: 5
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderSchedule = () => {
    if (!atmData?.schedule) return null;

    const switchStyles = {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#ffffff',
        '&:hover': {
          backgroundColor: 'rgba(255, 107, 0, 0.08)'
        }
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#ff6b00'
      }
    };

    return (
      <div className={styles.scheduleSection}>
        <div className={styles.scheduleHeader}>
          <div className={styles.leftSectionHeader}>{t("work_hours")}</div>
          <FormControlLabel
            control={
              <Switch
                checked={is24h}
                onChange={(e) => setIs24h(e.target.checked)}
                onClick={(e) => e.stopPropagation()}
                sx={switchStyles}
              />
            }
            label={t("24_7")}
          />
        </div>
        <div className={styles.daysContainer}>
          {renderDaySchedule('monday', t("monday"))}
          {renderDaySchedule('tuesday', t("tuesday"))}
          {renderDaySchedule('wednesday', t("wednesday"))}
          {renderDaySchedule('thursday', t("thursday"))}
          {renderDaySchedule('friday', t("friday"))}
          {renderDaySchedule('saturday', t("saturday"))}
          {renderDaySchedule('sunday', t("sunday"))}
        </div>
      </div>
    );
  };

  const formatTimeForApi = (time: string | undefined): string | undefined => {
    if (!time) return undefined;
    
    const [hours, minutes] = time.split(':');
    const paddedHours = hours.padStart(2, '0');
    const paddedMinutes = minutes.padStart(2, '0');
    return `0001-01-01T${paddedHours}:${paddedMinutes}:00.000000`;
  };

  const isScheduleValid = useCallback(() => {
    if (is24h) return true;
    for (const day of days) {
      const dayState = weekSchedule[day];
      if (dayState?.isActive) {
        const schedule = dayState.schedule;
        if (!schedule?.openTime || !schedule?.closedTime) {
          return false;
        }
      }
    }
    return true;
  }, [weekSchedule, days, is24h]);

  const isFormValid = useCallback(() => {
    if (!atmData) return false;
    if (!atmData.region?.trim()) return false;
    return true;
  }, [atmData]);

  const handleSave = async () => {
    if (!atmData || !isScheduleValid() || !isFormChanged) return;

    try {
      const scheduleForApi: { 
        [key: string]: Schedule | boolean;
        nonStop?: boolean;
      } = {
        nonStop: is24h
      };

      days.forEach(day => {
        const dayState = weekSchedule[day];
        const originalDayState = originalData?.weekSchedule[day];

        if (dayState?.isActive && dayState.schedule) {
          const isTimeChanged = (
            timeInputs[`${day}-openTime`] !== undefined ||
            timeInputs[`${day}-closedTime`] !== undefined ||
            timeInputs[`${day}-startLunchTime`] !== undefined ||
            timeInputs[`${day}-endLunchTime`] !== undefined ||
            dayState.schedule.flexibleLunchTime !== originalDayState?.schedule?.flexibleLunchTime
          );

          const schedule: any = {};

          if (timeInputs[`${day}-openTime`] !== undefined) {
            schedule.openTime = formatTimeForApi(dayState.schedule.openTime as string);
          } else if (originalDayState?.schedule?.openTime) {
            schedule.openTime = originalDayState.schedule.openTime;
          }

          if (timeInputs[`${day}-closedTime`] !== undefined) {
            schedule.closedTime = formatTimeForApi(dayState.schedule.closedTime as string);
          } else if (originalDayState?.schedule?.closedTime) {
            schedule.closedTime = originalDayState.schedule.closedTime;
          }

          if (timeInputs[`${day}-startLunchTime`] !== undefined) {
            schedule.startLunchTime = formatTimeForApi(dayState.schedule.startLunchTime as string);
          } else if (originalDayState?.schedule?.startLunchTime) {
            schedule.startLunchTime = originalDayState.schedule.startLunchTime;
          }

          if (timeInputs[`${day}-endLunchTime`] !== undefined) {
            schedule.endLunchTime = formatTimeForApi(dayState.schedule.endLunchTime as string);
          } else if (originalDayState?.schedule?.endLunchTime) {
            schedule.endLunchTime = originalDayState.schedule.endLunchTime;
          }

          schedule.flexibleLunchTime = dayState.schedule.flexibleLunchTime;

          scheduleForApi[day] = schedule;
        } else {
          scheduleForApi[day] = null;
        }
      });

      const formattedContacts = (atmData.contacts || []).map(contact => 
        String(contact).trim()
      ).filter(Boolean);

      const updatedData = {
        ...atmData,
        schedule: scheduleForApi,
        contacts: formattedContacts
      };

      const { response } = await apiRequest(
        "POST",
        `/admin/places/${atmId}`,
        updatedData
      );

      if (response?.data?.result === "SUCCESS") {
        enqueueSnackbar(t("saved_successfully"), { variant: "success" });
        history.push('/app/atms');
      } else {
        throw new Error("Failed to save");
      }
    } catch (error) {
      console.error("Error saving ATM details:", error);
      enqueueSnackbar(t("error_saving"), { variant: "error" });
    }
  };

  const handleReset = () => {
    if (originalData && atmData) {
      setWeekSchedule(JSON.parse(JSON.stringify(originalData.weekSchedule)));
      setIs24h(originalData.is24h);
      setTimeInputs({});
      setAtmData({
        ...atmData,
        is_open: originalData.is_open,
        address: originalData.address,
        region: originalData.region,
        geolocation: originalData.geolocation,
        contacts: [...originalData.contacts]
      });
      setIsFormChanged(false);
    }
  };

  const handleDelete = async () => {
    try {
      const { response } = await apiRequest(
        "DELETE",
        `/admin/places/${atmId}`,
        null
      );
      
      if (response?.data?.result === "SUCCESS") {
        history.push('/app/atms');
      } else {
        throw new Error("Failed to delete");
      }
    } catch (error) {
      console.error('Error deleting ATM:', error);
      enqueueSnackbar(t("error_deleting"), { variant: "error" });
    }
  };

  const getDayName = (day: string) => {
    return t(day);
  };

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(t("text_copied"), { variant: "success" });
  };

  const breadcrumbs = {
    step1Label: <span className={styles.breadcrumb_link}>{t("atms")}</span>,
    step1Url: "/app/atms",
    step2Label: atmData?.name || t("atm"),
    separator: <span className={styles.breadcrumb_separator}>&gt;</span>,
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!atmData) return;
    setAtmData({
      ...atmData,
      address: e.target.value
    });
    setIsFormChanged(true);
  };

  const handleRegionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!atmData) return;
    setAtmData({
      ...atmData,
      region: e.target.value
    });
    setIsFormChanged(true);
  };

  const handleCoordinatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCoordinatesInput(value);

    if (!atmData) return;

    const [lat, lon] = value.split(',').map(s => s?.trim());
    
    if (!value.trim()) {
      setAtmData({
        ...atmData,
        geolocation: {
          latitude: 0,
          longitude: 0
        }
      });
      setIsFormChanged(true);
      return;
    }

    const newLat = Number(lat);
    const newLon = Number(lon);
    if (!isNaN(newLat) && !isNaN(newLon)) {
      setAtmData({
        ...atmData,
        geolocation: {
          latitude: newLat,
          longitude: newLon
        }
      });
    }
    setIsFormChanged(true);
  };

  const handlePhoneChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!atmData) return;
    const newContacts = [...atmData.contacts];
    newContacts[index] = e.target.value;
    setAtmData({
      ...atmData,
      contacts: newContacts
    });
    setIsFormChanged(true);
  };

  return (
    <AppMainLayout
      breadcrumbs={breadcrumbs}
      navBarTitle={`${atmData?.name || `#${atmId}`}`}
      noBackground
    >
      <div className={styles.container}>
        {isFetching ? (
          <Spinner />
        ) : (
          <div className={styles.content}>
            <div className={styles.leftSection}>
              <div className={styles.leftSectionHeader}>{t("general")}</div>
              <div className={styles.infoGrid}>
                <div className={styles.inputField}>
                  <span className={styles.inputLabel}>{t("id")}</span>
                  <div className={styles.inputWrapper}>
                    <span className={styles.inputValue}>#{atmData?.id || ''}</span>
                  </div>
                </div>

                <div className={styles.inputField}>
                  <span className={styles.inputLabel}>{t("status")}</span>
                  <div className={styles.inputWrapper}>
                    <div className={`${styles.status} ${atmData?.is_open ? styles.active : styles.inactive}`}>
                      {atmData?.is_open ? t("Open").toUpperCase() : t("Closed").toUpperCase()}
                    </div>
                  </div>
                </div>

                <div className={styles.inputField}>
                  <span className={styles.inputLabel}>{t("address")}</span>
                  <div className={styles.inputWrapper}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={atmData?.address || ''}
                      onChange={handleAddressChange}
                    />
                  </div>
                </div>

                <div className={styles.inputField}>
                  <span className={styles.inputLabel}>{t("city")}</span>
                  <div className={styles.inputWrapper}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={atmData?.region || ''}
                      onChange={handleRegionChange}
                    />
                  </div>
                </div>

                <div className={styles.inputField}>
                  <span className={styles.inputLabel}>{t("coordinates")}</span>
                  <div className={styles.inputWrapper}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={coordinatesInput}
                      onChange={handleCoordinatesChange}
                      placeholder={t("coordinates_placeholder")}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  {Array.isArray(atmData?.contacts) && atmData?.contacts.map((contact, index) => (
                    <div key={index} className={styles.inputField}>
                      <span className={styles.inputLabel}>{t("phone")} {index + 1}</span>
                      <div className={styles.inputWrapper}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={contact || ''}
                          onChange={handlePhoneChange(index)}
                        />
                      </div>
                    </div>
                  ))}
                  <div 
                    className={styles.addPhoneButton}
                    onClick={() => {
                      if (atmData) {
                        setAtmData({
                          ...atmData,
                          contacts: [...atmData.contacts, '']
                        });
                      }
                    }}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#333333"/>
                    </svg>
                    {t("add_phone")}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightSection}>
              {renderSchedule()}
            </div>
          </div>
        )}
        <div className={styles.footer}>
          <div className={styles.footer_left_wrapper}>
            <div
              className={classNames(styles.saveButton, { 
                [styles.disabled]: isFetching || !isInitialized || !originalData || !isFormChanged || !isScheduleValid() || !isFormValid() 
              })}
              onClick={(!isFetching && isInitialized && originalData && isFormChanged && isScheduleValid() && isFormValid()) ? handleSave : undefined}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.59 3.59C17.21 3.21 16.7 3 16.17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V7.83C21 7.3 20.79 6.79 20.41 6.42L17.59 3.59ZM12 19C10.34 19 9 17.66 9 16C9 14.34 10.34 13 12 13C13.66 13 15 14.34 15 16C15 17.66 13.66 19 12 19ZM13 9H7C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5H13C14.1 5 15 5.9 15 7C15 8.1 14.1 9 13 9Z" fill="#FAFBFC" />
              </svg>
              <span>{t("save")}</span>
            </div>
            <div
              className={classNames(styles.resetButton, { [styles.disabled]: !isFormChanged })}
              onClick={isFormChanged ? handleReset : undefined}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.29068V3.50068C12 3.05068 11.46 2.83068 11.15 3.15068L8.35 5.94068C8.15 6.14068 8.15 6.45068 8.35 6.65068L11.14 9.44068C11.46 9.75068 12 9.53068 12 9.08068V7.29068C15.31 7.29068 18 9.98068 18 13.2907C18 16.0107 16.17 18.3107 13.69 19.0407C13.27 19.1607 13 19.5607 13 19.9907C13 20.6407 13.62 21.1507 14.25 20.9607C17.57 19.9907 20 16.9307 20 13.2907C20 8.87068 16.42 5.29068 12 5.29068Z" fill="#333333" />
                <path d="M6 13.2907C6 11.9507 6.44 10.7107 7.19 9.70068C7.49 9.30068 7.45 8.75068 7.1 8.39068C6.68 7.97068 5.96 8.01068 5.6 8.49068C4.6 9.83068 4 11.4907 4 13.2907C4 16.9307 6.43 19.9907 9.75 20.9607C10.38 21.1507 11 20.6407 11 19.9907C11 19.5607 10.73 19.1607 10.31 19.0407C7.83 18.3107 6 16.0107 6 13.2907Z" fill="#333333" />
              </svg>
              {t("reset")}
            </div>
          </div>
          <div
            className={styles.deleteButton}
            onClick={() => setShowDeleteConfirmModal(true)}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z" fill="white" />
            </svg>
            {t("delete")}
          </div>
        </div>
      </div>
      {showDeleteConfirmModal && (
        <DeleteConfirmModal
          onClose={() => setShowDeleteConfirmModal(false)}
          onConfirm={() => {
            handleDelete();
            setShowDeleteConfirmModal(false);
          }}
          locationName={atmData?.name}
        />
      )}
    </AppMainLayout>
  );
};

export default ATMDetails;
