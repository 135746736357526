import React from "react";

export const PercentIcon = () => {
  return (
    <svg
      width="61"
      height="71"
      viewBox="0 0 61 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="percent">
        <path
          id="Vector"
          d="M22.7868 11.8333C17.0772 11.8333 12.4326 16.4778 12.4326 22.1874C12.4326 27.897 17.0772 32.5416 22.7868 32.5416C28.4964 32.5416 33.141 27.897 33.141 22.1874C33.141 16.4778 28.4964 11.8333 22.7868 11.8333ZM22.7868 26.6249C20.3314 26.6249 18.3493 24.6428 18.3493 22.1874C18.3493 19.732 20.3314 17.7499 22.7868 17.7499C25.2422 17.7499 27.2243 19.732 27.2243 22.1874C27.2243 24.6428 25.2422 26.6249 22.7868 26.6249ZM49.4118 38.4583C43.7022 38.4583 39.0576 43.1028 39.0576 48.8124C39.0576 54.522 43.7022 59.1666 49.4118 59.1666C55.1214 59.1666 59.766 54.522 59.766 48.8124C59.766 43.1028 55.1214 38.4583 49.4118 38.4583ZM49.4118 53.2499C46.9564 53.2499 44.9743 51.2678 44.9743 48.8124C44.9743 46.357 46.9564 44.3749 49.4118 44.3749C51.8672 44.3749 53.8493 46.357 53.8493 48.8124C53.8493 51.2678 51.8672 53.2499 49.4118 53.2499ZM57.6655 13.9337C58.8193 15.0874 58.8193 16.9512 57.6655 18.1049L18.7043 57.0662C17.5505 58.2199 15.6868 58.2199 14.533 57.0662C13.3793 55.9124 13.3793 54.0487 14.533 52.8949L53.4943 13.9337C54.648 12.7799 56.5118 12.7799 57.6655 13.9337Z"
          fill="#E6E6E6"
        />
      </g>
    </svg>
  );
};
