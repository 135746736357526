import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../../infrastructure/components/Button";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import { ITopNavBarComponentProps } from "./types";
import { getIsAuthFetching } from "../../../api/selectors/authSelector";
import { getAccessToken } from "../../../api/selectors/tokenSelector";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./top-nav-bar.module.scss";
import classNames from "classnames";
import { SettingsIcon } from "../../../svg-icons/icon-settings";
import { NotificationsIcon } from "../../../svg-icons/icon-notifications";
import { Link } from "react-router-dom";
import { navigation } from "../../../constants/data";
import { getNotificationCount, getPrevNotificationCount } from "../../../api/selectors/mainSelector";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import axios, { apiRequest } from "../../../api/services/Api";
import ButtonComponent from "../ButtonComponent";
import { setToken, setTokenInHeaders } from "../../../api/actionCreators/authActionCreator";
import { ExitIcon } from "../../../svg-icons/icon-exit";
import { PersonIcon } from "../../../svg-icons/icon-person";
import Drawer from "@mui/material/Drawer";
import FormLabeledInput from "../FormComponents/FormLabeledInput/FormLabeledInput";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
// import { SettingsModal } from "../SettingsModal/SettingsModal";
import SettingsModal from "./settings-modal";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TopNavBarComponent({
  navBarTitle,
  className,
  breadcrumbs = null,
  onExit,
}: ITopNavBarComponentProps) {
  const mockMerchantInfoDefault = {
    merchantId: null,
    legalName: null,
    tradeName: null,
    idno: null,
    iban: null,
    userId: null,
    idnp: null,
    phoneNumber: null,
    email: null,
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [userRole, setUserRole] = useState("");

  const data = useSelector(getAccessToken);
  const token = useSelector(getAccessToken);
  const isAuthFetching = useSelector(getIsAuthFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem('_user_data');
    if (userData) {
      const parsedData = JSON.parse(userData);
      setUserRole(parsedData.role);
    }
  }, []);

  const handleLogout = async () => {
    setExiting(true)
    try {
      // const response = await axios.post('/admin/auth/logout');

      const { status, response } = await apiRequest(
        "post",
        `/admin/auth/logout`,
        null
      );
      console.log("response", response)
      if (status === 200) {
        dispatch(setToken(null));
        dispatch(setTokenInHeaders(false));
        localStorage.removeItem('_session_token');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
    setExiting(false)
    setOpen(false);
  };


  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (!password.trim()) {
      setPasswordError(t("Required_field"));
      return {
        success: false,
        error: t("Required_field")
      };
    }

    setIsChangePassword(true);
    try {
      const userData = localStorage.getItem('_user_data');
      if (!userData) {
        console.error('User data not found in session');
        enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
        return {
          success: false,
          error: t("Error_changing_password")
        };
      }

      const parsedData = JSON.parse(userData);
      const userId = parsedData?.userId;
      if (!userId) {
        console.error('User ID not found in session');
        enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
        return {
          success: false,
          error: t("Error_changing_password")
        };
      }
      const { status } = await apiRequest(
        "patch",
        `/admin/user/${userId}`,
        {
          newPassword: password
        }
      );
      
      if (status === 200) {
        setPassword("");
        setShowPasswordModal(false);
        enqueueSnackbar(t("Password_changed_successfully"), { variant: "success" });
        setPasswordError(null);
        setIsChangePassword(false);
        return {
          success: true,
          message: t("Password_changed_successfully")
        };
      } else {
        enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
        return {
          success: false,
          error: t("Error_changing_password")
        };
      }
    } catch (error) {
      console.error('Password change error:', error);
      enqueueSnackbar(t("Error_changing_password"), { variant: "error" });
      return {
        success: false,
        error: t("Error_changing_password")
      };
    } finally {
      setPasswordError(null);
      setIsChangePassword(false);
    }
  };

  const closePasswordModalHandler = () => {
    setPassword("");
    setShowPasswordModal(false);
    setShowPassword(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderExitModal = () => {


    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Logout")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_logout")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              icon={
                exiting && <CircularProgress className="circular-progress" size={18} />
              }
              className={styles.login_button}
              label={t("Exit")}
              disabled={exiting}
              onClick={() => handleLogout()}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              onClick={() => setOpen(false)}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderUserInfoDrawer = () => {
    return (
      <SwipeableDrawer
        anchor={"right"}
        open={showUserInfo}
        onClose={() => setShowUserInfo(false)}
        onOpen={() => setShowUserInfo(true)}
      >
        <Box
          sx={{ width: 450 }}
          role="presentation"
        // onClick={() => setShowUserInfo(false)}
        // onKeyDown={() => setShowUserInfo(false)}
        >
          <List>
            <h3>{t("Merchant_info")}</h3>
            <div className={styles.merchant_info}>
              <div>{t("Merchant_id")}</div>
              <div>{mockMerchantInfoDefault?.merchantId}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Legal_name")}</div>
              <div>{mockMerchantInfoDefault?.legalName}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Trade_name")}</div>
              <div>{mockMerchantInfoDefault?.tradeName}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Merchant_idno")}</div>
              <div>{mockMerchantInfoDefault?.idno}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Merchnat_iban")}</div>
              <div>{mockMerchantInfoDefault?.iban}</div>
            </div>
            <Divider />
            <h3>{t("Admin_info")}</h3>
            <div className={styles.merchant_info}>
              <div>{t("Admin_id")}</div>
              <div>#{mockMerchantInfoDefault?.userId}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Admin_idnp")}</div>
              <div>{mockMerchantInfoDefault?.idnp}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>{t("Phone_number")}</div>
              <div>{mockMerchantInfoDefault?.phoneNumber}</div>
            </div>

            <div className={styles.merchant_info}>
              <div>Email</div>
              <div>{mockMerchantInfoDefault?.email}</div>
            </div>
          </List>
          <Divider />
        </Box>
      </SwipeableDrawer>
    );
  };

  const renderPasswordModal = () => {
    return (
      <Dialog
        open={showPasswordModal}
        onClose={closePasswordModalHandler}
        aria-labelledby="password-dialog-title"
        aria-describedby="password-dialog-description"
        className={styles.custom_dialog}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle
            id="password-dialog-title"
            className={styles.setting_modal_title}
          >
            {t("Enter_password")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="password-dialog-description">
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="password-input">{t("Password")}</label>
                <TextField
                  id="password-input"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  className={styles.modal_input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              label={t("save")}
              onClick={handlePasswordSubmit}
              disabled={isChangePassword || !password}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              onClick={closePasswordModalHandler}
              // disabled={isChangePassword}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };
  
  console.log('userRole', userRole);

  return (
    <>
      {renderExitModal()}
      {renderUserInfoDrawer()}
      {renderPasswordModal()}
      {showSettingsModal && (
        <SettingsModal {...{ setShowSettingsModal, showSettingsModal }} />
      )}
      {redirect && <Redirect to="/" />}
      <div
        className={classNames({
          [`${styles.top_nav_bar_wrapper}`]: true,
          [className]: true,
        })}
      >
        <div className={styles.top_bar_page_name}>
          {breadcrumbs && breadcrumbs.step2Label && (
            <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs}>
              <Link to={breadcrumbs.step1Url}>
                <Typography>{breadcrumbs.step1Label}</Typography>
              </Link>
              `&gt;`{" "}
              {breadcrumbs.step2Label && (
                <Typography>{breadcrumbs.step2Label}</Typography>
              )}
            </Breadcrumbs>
          )}
          <Typography
            className={styles.page_title}
            component={"span"}
            variant={"body2"}
          >
            {navBarTitle}
          </Typography>
        </div>
        <div className={styles.top_bar_page_user_wrapper}>
          {/* <div className={styles.notification_icon_wrapper}>
            <NotificationsIcon />
          </div> */}
          <div className={styles.settings_icon_wrapper}>
            <SettingsIcon onClick={
              () => setShowSettingsModal(true)
            } />
          </div>

          <div className={styles.user_name_info_wrapper}>
            <div
              className={styles.user_login}
              onClick={() => userRole === 'EMPLOYEE' && setShowPasswordModal(true)}
            >
              <span>{data?.phoneNumber}</span>
              <PersonIcon />
            </div>
          </div>
          <div
            className={styles.notification_icon_wrapper}
            onClick={() => setOpen(true)}
          >
            <ExitIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavBarComponent;
