import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react";
import { Link } from "react-router-dom";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { SearchIcon } from "../../svg-icons/icon-search";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
import { RestartIcon } from "../../svg-icons/icon-restart";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./clients.module.scss";
import { ClientListItem } from "../../api/services/Clients";
import { fetchClients } from "../../api/services/Clients";
import { useSnackbar } from "notistack";

interface Client {
  id: number;
  idnp: string;
  fullName: string;
  lastActivityDate: string;
  phone: string;
  address: string;
  status: "ACTIVE" | "INACTIVE";
}

interface FormErrors {
  idnp?: string;
  fullName?: string;
  phone?: string;
  address?: string;
  status?: string;
}

const filtersSellers = ["all", "ACTIVE", "INACTIVE"] as const;

const Clients: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<ClientListItem[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [idnpText, setIdnpText] = useState<string>("");
  const [nameText, setNameText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sellerStatus, setSellerStatus] = useState<"all" | "ACTIVE" | "INACTIVE">("all");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [totalCount, setTotalCount] = useState<number>(0);

  const [newClient, setNewClient] = useState<{
    idnp: string;
    fullName: string;
    phone: string;
    address: string;
    dob: string;
    status: "ACTIVE" | "INACTIVE";
  }>({
    idnp: "",
    fullName: "",
    phone: "",
    address: "",
    dob: "",
    status: "ACTIVE",
  });

  const fetchClientsData = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await fetchClients(
        idnpText,
        nameText,
        sellerStatus,
        currentPage,
        pageSize,
        t
      );

      if (response.success && response.data) {
        setRows(response.data.data as ClientListItem[]);
        setTotalCount(response.data.pagination.totalCount);
      } else {
        enqueueSnackbar(response.error || t("Something_went_wrong"), {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      enqueueSnackbar(t("Something_went_wrong"), {
        variant: "error",
      });
    } finally {
      setIsFetching(false);
    }
  }, [idnpText, nameText, sellerStatus, currentPage, pageSize, t, enqueueSnackbar]);

  useEffect(() => {
    fetchClientsData();
  }, [fetchClientsData]);

  const handleNewClientChange = (
    field: keyof typeof newClient,
    value: string | "ACTIVE" | "INACTIVE"
  ) => {
    setNewClient((prev) => ({
      ...prev,
      [field]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const paginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleIDNPChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIdnpText(e.target.value);
    setCurrentPage(0);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameText(e.target.value);
    setCurrentPage(0);
  };

  const handleResetFilters = () => {
    setIdnpText("");
    setNameText("");
    setSellerStatus("all");
    setCurrentPage(0);
  };

  const renderColumns = () => {
    return [
      {
        field: "idnp",
        headerName: t("IDNP"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: ClientListItem }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.idnp}
          </Link>
        ),
      },
      {
        field: "fullName",
        headerName: t("Name_surname"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: ClientListItem }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.fullName}
          </Link>
        ),
      },
      {
        field: "phone",
        headerName: t("Phone"),
        flex: 0.5,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: ClientListItem }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.phone}
          </Link>
        ),
      },
      {
        field: "address",
        headerName: t("Address"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: ClientListItem }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.address}
          </Link>
        ),
      },
      {
        field: "lastActivityDate",
        headerName: t("Last_activity"),
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: ClientListItem }) => (
          <Link to={`/app/clients/${row.id}`} className={styles.no_underline}>
            {row.lastActivityDate ? moment(row.lastActivityDate).format("DD-MM-YYYY HH:mm") : "-"}
          </Link>
        ),
      },
      //TBD
      // {
      //   field: "status",
      //   headerName: t("Status"),
      //   flex: 0.5,
      //   headerClassName: styles.table_header,
      //   cellClassName: styles.table_cell,
      //   renderCell: ({ row }: { row: ClientListItem }) => (
      //     <div className={styles.role_wrapper}>{t(row.status)}</div>
      //   ),
      // },
    ];
  };

  const renderFilterItems = () => {
    return filtersSellers.map((filter) => (
      <button
        key={filter}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: sellerStatus === filter,
        })}
        onClick={() => setSellerStatus(filter)}
      >
        {filter === "all"
          ? t("All")
          : t(filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase())}
      </button>
    ));
  };

  return (
    <AppMainLayout navBarTitle={t("Clients")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl>
          <OutlinedInput
            id="outlined-adornment-idnp"
            startAdornment={<SearchIcon />}
            placeholder={t("IDNP")}
            value={idnpText}
            onChange={handleIDNPChange}
            className={styles.search_input}
            type="number"
            autoComplete="off"
          />
        </FormControl>

        <FormControl>
          <OutlinedInput
            id="outlined-adornment-name"
            startAdornment={<SearchIcon />}
            placeholder={t("Name")}
            value={nameText}
            onChange={handleNameChange}
            className={styles.search_input}
            type="text"
          />
        </FormControl>

        <Button
          variant="primary"
          icon={<RestartIcon />}
          label={t("Reset")}
          // disabled={isFetching}
          className={styles.reset_btn}
          onClick={handleResetFilters}
        />
      </div>

      {/* <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div> */}

      <DataTableComponent
        checkboxSelection={false}
        columns={renderColumns()}
        isFetching={isFetching}
        idChangeValue={"id"}
        className={styles.sellers_table}
        rows={rows}
      />

      <Pagination
       onClick={paginationClick}
        onChangePageSize={handlePageSizeChange}
         total={totalCount}
        pageIndex={currentPage}
        pageSize={pageSize}
      />
    </AppMainLayout>
  );
};

export default Clients;
