import React from "react";
import SignInPage from "./pages/SignInPage";
import Users from "./pages/Users";
import Clients from "./pages/Clients";
import ClientDetails from "./pages/ClientDetails";
import ATMS from "./pages/ATMS";
import Promocodes from "./pages/Promocodes";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccessToken } from "./api/selectors/tokenSelector";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "./App.scss";
import ATMDetails from "./pages/ATMDetails";

const App = () => {
  const token = useSelector(getAccessToken);
  const storedToken = localStorage.getItem('_session_token');
  const isAuthenticated = token && storedToken && token === storedToken;

  // Ensure the app is mounted
  React.useEffect(() => {
    // This ensures we're in a browser environment before any modal calculations
    if (typeof window !== 'undefined') {
      // Force a reflow to ensure proper modal measurements
      window.document.body.style.overflow = window.document.body.style.overflow;
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Switch>
        <Route exact path="/" render={() => {
          return isAuthenticated ? <Redirect to="/app/users" /> : <SignInPage />;
        }} />
        <Route path="/app/users" exact component={Users} />
        <Route path="/app/clients" exact component={Clients} />
        <Route path="/app/clients/:clientId" exact component={ClientDetails} />
        <Route path="/app/atms" exact component={ATMS} />
        <Route path="/app/atms/:atmId" exact component={ATMDetails} />
        <Route path="/app/promocodes" exact component={Promocodes} />
      </Switch>
    </LocalizationProvider>
  );
};

export default App;
