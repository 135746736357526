import { apiRequest } from "./Api";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import { AxiosResponse } from "axios";

export interface ApiResponse<T = void> {
  data?: T;
  success: boolean;
  error?: string;
  message?: string;
  result?: string;
}

export interface ApiClientResponse {
  result: string;
  data: ClientListItem[];
  pagination: {
    totalCount: number;
  };
  message?: string;
}

export interface Account {
  id: number;
  alias: string | "-";
  accountName: string;
  accountNumber: string;
  currency: string;
  balance: number;
}

export interface Deposit {
  id: number;
  alias: string | "-";
  startDate: string;
  endDate: string;
  conditions: string | "-";
  amount: number;
  currency: string;
}

export interface Credit {
  id: number;
  alias: string | "-";
  startDate: string;
  endDate: string;
  amount: number;
  conditions: string | "-";
  currency: string;
}

export interface Card {
  id: number;
  alias: string | "-";
  cardNumber: string;
  expiryDate: string;
  balance: number;
  currency: string;
}

export interface Session {
  id: string;
  sessionStart: string;
  sessionEnd: string | "-";
  ipAddress: string | "-";
  geoLocation: {
    latitude: number | "-";
    longitude: number | "-";
  } | "-";
  deviceInfo: {
    platform: string | "-";
    version: string | "-";
    manufacturer: string | "-";
    model: string | "-";
  } | "-";
  appVersion: string | "-";
}

export interface PersonalInfo {
  fullName: string;
  idnp: string;
  phone: string;
  email: string | "-";
  address: string;
  identityDocument: string | "-";
  dob: string | "-";
  riskLevel: string | "-";
}

export interface ClientData {
  personalInfo: PersonalInfo;
  activeProducts: {
    accounts: Array<Account>;
    deposits: Array<Deposit>;
    credits: Array<Credit>;
    cards: Array<Card>;
  };
  sessions: Array<Session>;
}

export interface ClientDetailsResponse {
  result: "SUCCESS" | "ERROR";
  data: ClientData;
}

export interface ClientListItem {
  id: number;
  idnp: string;
  fullName: string;
  lastActivityDate: string;
  phone: string;
  address: string;
  dob: string;
  status: "ACTIVE" | "INACTIVE";
}

export type Client = ClientData & {
  status: "ACTIVE" | "INACTIVE";
  createdAt: string;
};

const handle401Error = (t: TFunction) => {
  enqueueSnackbar(t("Session_expired_redirecting"), { 
    variant: "error", 
    autoHideDuration: 3000 
  });
  
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const fetchClients = async (
  idnp: string,
  name: string,
  status: string,
  currentPage: number,
  pageSize: number,
  t: TFunction
): Promise<ApiResponse<ApiClientResponse>> => {
  try {
    const newIdnp = idnp === "" ? "" : `=${idnp}`;
    const newName = name === "" ? "" : `=${name}`;
    const newStatus = status === "all" ? "" : `=${status}`;
    
    const { status: responseStatus, response } = await apiRequest(
      "get",
      `/admin/mb-user?page=${currentPage + 1}&pageSize=${pageSize}&idnp${newIdnp}&name${newName}&status${newStatus}`,
      null
    );

    if (responseStatus === 401) {
      handle401Error(t);
      return {
        success: false,
        error: t("Session_expired")
      };
    }

    if (responseStatus === 200 && response.data.result === "SUCCESS") {
      return {
        success: true,
        data: response.data
      };
    }

    return {
      success: false,
      error: response.data.message || t("Something_went_wrong")
    };
  } catch (error) {
    console.error("Error fetching clients:", error);
    return {
      success: false,
      error: t("Something_went_wrong")
    };
  }
};

export const fetchClientDetails = async (
  clientId: string,
  t: TFunction
): Promise<ClientDetailsResponse> => {
  try {
    const { response } = await apiRequest(
      "GET",
      `/admin/mb-user/${clientId}`,
      null
    ) as { response: AxiosResponse<ClientDetailsResponse> };
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      handle401Error(t);
    }
    throw error;
  }
};
