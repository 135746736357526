/* eslint-disable no-useless-escape */
import React, { useState, ChangeEvent, useEffect, useCallback } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { SearchIcon } from "../../svg-icons/icon-search";
import Pagination from "../../infrastructure/components/Pagination";
import Button from "../../infrastructure/components/Button";
import { PlusIcon } from "../../svg-icons/icon-plus";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "./users.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useDebounce from '../../hooks/useDebounce';
import InputMask from "react-input-mask";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { User, FormErrors, Column, NewUser } from "./interfaces/types";
import { fetchUsers, addUser, changePassword } from "../../api/services/Users";
import { useSnackbar } from 'notistack';
import useModalOverflow from '../../hooks/useModalOverflow';

const filtersRoles = ["", "ADMIN", "EMPLOYEE"] as const;

const Users: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<User[]>([]);
  const [rawRows, setRawRows] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [changedUserId, setChangedUserId] = useState<number>(null);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [roleFilter, setRoleFilter] = useState<"" | "ADMIN" | "EMPLOYEE">("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [columns, setColumns] = useState<Column[]>([]);

  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const [newUser, setNewUser] = useState<NewUser>({
    username: "",
    password: "",
    email: "",
    role: "EMPLOYEE",
    name: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // Handle body overflow for modals
  useModalOverflow(openAddUserModal || openPasswordModal);

  const formatRows = useCallback((rawData: User[]) => {
    return rawData.map(row => ({
      ...row,
      role: t(row.role)
    }));
  }, [t]);

  useEffect(() => {
    setColumns(renderColumns());
  }, [i18n.language]);

  useEffect(() => {
    setRows(formatRows(rawRows));
  }, [i18n.language, rawRows, formatRows]);

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (!newUser.username.trim()) errors.username = t("Required_field");
    if (!newUser.password.trim()) errors.password = t("Required_field");
    if (!newUser.email.trim()) {
      errors.email = t("Required_field");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(newUser.email)) {
        errors.email = t("Invalid_email");
      }
    }
    if (!["ADMIN", "EMPLOYEE"].includes(newUser.role)) {
      errors.role = t("Invalid_role");
    }
    if (!newUser.phone.trim()) {
      errors.phone = t("Required_field");
    } else {
      const phone = newUser.phone
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\ /g, "")
        .replace(/\+/g, "")
        .slice(3);

      if (
        !newUser.phone ||
        newUser.phone.length === 0 ||
        phone.length !== 8
      ) {
        errors.phone = t("Invalid_phone");
      }
    }
    if (!newUser.name.trim()) {
      errors.name = t("Required_field");
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useDebounce(async () => {
    setIsFetching(true);
    const result = await fetchUsers(roleFilter, searchText, currentPage, pageSize, t);
    if (result.success && result.data) {
      setRawRows(result.data.users);
      setRows(formatRows(result.data.users));
      setTotalCount(result.data.totalCount);
      setError(null);
    } else {
      setRawRows([]);
      setRows([]);
      setTotalCount(0);
      setError(result.error || t("Error_loading_users"));
    }
    setIsFetching(false);
  }, [roleFilter, searchText, currentPage, pageSize, formatRows], 500);

  const paginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setCurrentPage(0);
  };

  const handleRoleFilterChange = (role: "" | "ADMIN" | "EMPLOYEE") => {
    setRoleFilter(role);
    setCurrentPage(0);
  };

  const renderColumns = (): Column[] => {
    return [
      {
        field: "username",
        headerName: t("Username"),
        flex: 0.7,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.email_cell}>{row.username}</div>
        ),
      },
      {
        field: "email",
        headerName: t("Email"),
        flex: 1.4,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.email_cell}>{row.email}</div>
        ),
      },
      {
        field: "name",
        headerName: t("Name_surname"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.no_underline}>{row.name}</div>
        ),
      },
      {
        field: "phone",
        headerName: t("Phone"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => row.phone,
      },
      {
        field: "createdAt",
        headerName: t("Registration_date"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) =>
          moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
      },
      {
        field: "role",
        headerName: t("Role"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }: { row: User }) => (
          <div className={styles.role_wrapper}>{row.role}</div>
        ),
      },
    ];
  };

  const handleEmailClick = (user: User) => {
    setChangedUserId(user.id)
    setOpenPasswordModal(true);
  };

  const renderFilterItems = () => {
    return filtersRoles.map((filter) => (
      <button
        key={filter}
        className={classNames({
          [styles.filter_button]: true,
          [styles.filter_button_active]: roleFilter === filter,
        })}
        onClick={() =>
          handleRoleFilterChange(filter as "ADMIN" | "EMPLOYEE")
        }
      >
        {filter === ""
          ? t("All")
          : t(filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase())}
      </button>
    ));
  };

  const openAddUserModalHandler = () => {
    setOpenAddUserModal(true);
  };

  const closeAddUserModal = () => {
    setOpenAddUserModal(false);
    setNewUser({
      username: "",
      password: "",
      email: "",
      role: "EMPLOYEE",
      name: "",
      phone: "",
    });
    setFormErrors({});
  };

  const handleNewUserChange = (field: keyof typeof newUser, value: string) => {
    setNewUser((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: undefined,
    }));
  };

  const handleAddNewUser = async () => {
    const isValidForm = validateForm();
    if (isValidForm) {
      setIsAdding(true);
      const result = await addUser(newUser, t);
      if (result.success) {
        if (result.shouldCloseModal) {
          closeAddUserModal();
          const fetchResult = await fetchUsers(roleFilter, searchText, currentPage, pageSize, t);
          if (fetchResult.success && fetchResult.data) {
            setRawRows(fetchResult.data.users);
            setRows(formatRows(fetchResult.data.users));
            setTotalCount(fetchResult.data.totalCount);
          }
        }
      } else {
        enqueueSnackbar(t("Error_adding_user"), { variant: 'error' });
      }
      setIsAdding(false);
    }
  };

  const addNewUserModal = () => {
    return (
      <Dialog
        open={openAddUserModal}
        onClose={closeAddUserModal}
        aria-labelledby="add-user-dialog-title"
        aria-describedby="add-user-dialog-description"
        className={styles.custom_dialog}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle
            id="add-user-dialog-title"
            className={styles.setting_modal_title}
          >
            {t("Add_New_User")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="add-user-dialog-description">
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="username">{t("Login")}</label>
                <TextField
                  id="username"
                  variant="outlined"
                  placeholder={t("Login")}
                  fullWidth
                  value={newUser.username}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("username", e.target.value)
                  }
                  error={!!formErrors.username}
                  helperText={formErrors.username}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="name">{t("Name")}</label>
                <TextField
                  id="name"
                  variant="outlined"
                  fullWidth
                  placeholder={t("Name")}
                  value={newUser.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("name", e.target.value)
                  }
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="password">{t("Password")}</label>
                <TextField
                  id="password"
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  fullWidth
                  value={newUser.password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("password", e.target.value)
                  }
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  className={styles.modal_input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="email">{t("Email")}</label>
                <TextField
                  id="email"
                  variant="outlined"
                  type="email"
                  placeholder={t("Email")}
                  fullWidth
                  value={newUser.email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleNewUserChange("email", e.target.value)
                  }
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  className={styles.modal_input}
                />
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="role">{t("Role")}</label>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.role}
                  className={styles.modal_input}
                >
                  <Select
                    id="role"
                    value={newUser.role}
                    onChange={(e: SelectChangeEvent<string>) =>
                      handleNewUserChange("role", e.target.value as "ADMIN" | "EMPLOYEE")
                    }
                  >
                    <MenuItem value="ADMIN">{t("Admin")}</MenuItem>
                    <MenuItem value="EMPLOYEE">{t("Employee")}</MenuItem>
                  </Select>
                  {formErrors.role && (
                    <FormHelperText>{formErrors.role}</FormHelperText>
                  )}
                </FormControl>
              </div>

              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="phone">{t("Phone")}</label>
                <InputMask
                  mask="(+373) 99999999"
                  placeholder="+37369000000"
                  value={newUser.phone}
                  onChange={(e) => {
                    handleNewUserChange("phone", e.target.value)
                  }}
                  maskChar={""}
                >
                  {() => (
                    <TextField
                      fullWidth
                      id="phone"
                      placeholder="+373 69000000"
                      variant="outlined"
                      helperText={formErrors.phone}
                      className={styles.modal_input}
                      error={!!formErrors.phone}
                      inputProps={{
                        tabIndex: 2,
                      }}
                    />
                  )}
                </InputMask>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              icon={isAdding ? <CircularProgress className="circular-progress" size={18} /> : <PlusIcon />}
              label={t("New")}
              disabled={isAdding}
              onClick={() => isAdding ? {} : handleAddNewUser()}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              disabled={isAdding}
              onClick={closeAddUserModal}
            />
          </DialogActions>
        </div>
      </Dialog>
    )
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (!password.trim()) {
      setPasswordError(t("Required_field"));
      return;
    }

    setIsChangePassword(true);
    const result = await changePassword(changedUserId, password, t);
    if (result.success) {
      setPassword("");
      setOpenPasswordModal(false);
    }
    setPasswordError(null);
    setIsChangePassword(false);
  };

  const closePasswordModalHandler = () => {
    setPassword("");
    setChangedUserId(null);
    setOpenPasswordModal(false);
    setShowPassword(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordModal = () => {
    return (
      <Dialog
        open={openPasswordModal}
        onClose={closePasswordModalHandler}
        aria-labelledby="password-dialog-title"
        aria-describedby="password-dialog-description"
        className={styles.custom_dialog}
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle
            id="password-dialog-title"
            className={styles.setting_modal_title}
          >
            {t("Enter_password")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText id="password-dialog-description">
              <div className={classNames(styles.info_block_input_container)}>
                <label htmlFor="password-input">{t("Password")}</label>
                <TextField
                  id="password-change-input"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("Password")}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  className={styles.modal_input}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="primary"
              label={t("save")}
              onClick={handlePasswordSubmit}
              disabled={isChangePassword || !password}
            />
            <Button
              variant="ghost"
              label={t("Cancel")}
              onClick={closePasswordModalHandler}
              disabled={isChangePassword}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout navBarTitle={t("Users")}>
      <div className={styles.customer_page_bottom_container}>
        <FormControl fullWidth>
          <OutlinedInput
            id="search-users"
            autoComplete="off"
            startAdornment={<SearchIcon />}
            placeholder={t("Search_by_username_name_phone")}
            value={searchText}
            onChange={handleSearchChange}
            className={styles.search_input}
          />
        </FormControl>

        <Button
          variant="primary"
          icon={<PlusIcon />}
          label={t("New")}
          onClick={openAddUserModalHandler}
          className={styles.add_new_btn}
        />
      </div>

      <div className={styles.calendar_and_filters_container}>
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      <DataTableComponent
        checkboxSelection={false}
        columns={columns}
        isFetching={isFetching}
        idChangeValue="id"
        className={styles.users_table}
        rows={rows}
        onCellClick={(params) => handleEmailClick(params.row)}
      />

      <Pagination
        onClick={paginationClick}
        onChangePageSize={handlePageSizeChange}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={pageSize}
      />

      {addNewUserModal()}
      {passwordModal()}
    </AppMainLayout>
  );
};

export default Users;
