import React from "react";

export const SavingIcon = () => {
  return (
    <svg
      width="61"
      height="71"
      viewBox="0 0 61 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="savings">
        <path
          id="Vector"
          d="M59.0645 23.6668L52.349 16.9514C52.5561 15.7089 52.8815 14.5551 53.2957 13.5493C53.6211 12.7801 53.7395 11.8926 53.562 10.9755C53.1774 8.84553 51.1066 7.36636 48.9174 7.39594C44.2136 7.48469 40.0424 9.79219 37.3799 13.3126H22.5882C13.5949 13.3126 6.31738 20.5901 6.31738 29.5834C6.31738 36.2397 10.3703 51.7118 12.4707 59.2851C13.1807 61.8293 15.5178 63.6043 18.1803 63.6043H24.0674C27.3216 63.6043 29.9841 60.9418 29.9841 57.6876H35.9007C35.9007 60.9418 38.5632 63.6043 41.8174 63.6043H47.7636C50.367 63.6043 52.6745 61.8884 53.4436 59.3739L57.1416 47.0672L63.4724 44.9372C64.6853 44.523 65.4841 43.3989 65.4841 42.1268V26.6251C65.4841 24.998 64.1528 23.6668 62.5257 23.6668H59.0645ZM35.9007 28.1043H27.0257C25.3986 28.1043 24.0674 26.773 24.0674 25.1459C24.0674 23.5189 25.3986 22.1876 27.0257 22.1876H35.9007C37.5278 22.1876 38.8591 23.5189 38.8591 25.1459C38.8591 26.773 37.5278 28.1043 35.9007 28.1043ZM47.7341 34.0209C46.107 34.0209 44.7757 32.6897 44.7757 31.0626C44.7757 29.4355 46.107 28.1043 47.7341 28.1043C49.3611 28.1043 50.6924 29.4355 50.6924 31.0626C50.6924 32.6897 49.3611 34.0209 47.7341 34.0209Z"
          fill="#E6E6E6"
        />
      </g>
    </svg>
  );
};
