import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAccessToken } from '../api/selectors/tokenSelector';
import { setToken, setTokenInHeaders } from '../api/actionCreators/authActionCreator';

export const withAuth = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const token = useSelector(getAccessToken); // токен из Redux store
    const dispatch = useDispatch();

    useEffect(() => {
      const storedToken = localStorage.getItem('_session_token'); // токен из localStorage
      
      // Если токены не совпадают или отсутствуют, делаем логаут
      if (!token || !storedToken || token !== storedToken) {
        dispatch(setToken(null));
        dispatch(setTokenInHeaders(null));
        localStorage.removeItem('_session_token');
        return;
      }
    }, [token, dispatch]);

    // Если нет токена, редиректим на логин
    if (!token) {
      return <Redirect to="/" />;
    }

    return <WrappedComponent {...props} />;
  };
};
