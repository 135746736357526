import React from 'react';
import styles from './spinner.module.scss';

interface SpinnerProps {
  size?: number;
  color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 30, color = '#E5891D' }) => {
  return (
    <div 
      className={styles.spinner}
      style={{ 
        width: size,
        height: size,
        borderColor: color,
        borderTopColor: 'transparent'
      }}
    />
  );
};

export default Spinner;
