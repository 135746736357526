import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import styles from "./promocodes.module.scss";
import Pagination from "../../infrastructure/components/Pagination";
import { apiRequest } from "../../api/services/Api";
import Button from "../../infrastructure/components/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CalendarIcon } from "../../svg-icons/icon-calendar";
import { useSelector } from "react-redux";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";
import "dayjs/locale/en";
import "dayjs/locale/ro";
import "dayjs/locale/ru";
import ImportModal from "./components/ImportModal";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";

const LAST_MONTH = 1;
const LAST_THREE_MONTHS = 2;
const QUARTER = 3;
const CUSTOM_PERIOD = 4;
const FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";

interface PromoCodeReport {
  name: string;
  phone: string;
  promoCode: string;
  operationType: string;
  successCount: number;
}

interface PromoCodeResponse {
  result: string;
  reportCsv: string;
  reports: PromoCodeReport[];
  pagination: {
    totalCount: number;
  };
}

interface ExportResponse {
  result: string;
  report: string;
}

const Promocodes: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const language = useSelector(getCurrentLanguage);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (typeof document !== "undefined" && document.documentElement) {
      setIsMounted(true);
    }
  }, []);

  const [rows, setRows] = useState<PromoCodeReport[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [period, setPeriod] = useState(LAST_MONTH);
  const [customPeriodDialogOpen, setCustomPeriodDialogOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState<string>(
    dayjs().subtract(31, "day").startOf("day").format(TIME_FORMAT)
  );
  const [dateTo, setDateTo] = useState<string>(
    dayjs().endOf("day").format(TIME_FORMAT)
  );
  const [modalDateFrom, setModalDateFrom] = useState<Dayjs>(dayjs(dateFrom));
  const [modalDateTo, setModalDateTo] = useState<Dayjs>(dayjs(dateTo));
  const [showImportModal, setShowImportModal] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredRows, setFilteredRows] = useState<PromoCodeReport[]>([]);

  const columns = [
    {
      field: "promoCode",
      headerName: t("Promo_Code"),
      flex: 0.7,
    },
    {
      field: "name",
      headerName: t("Name_surname"),
      flex: 0.7,
    },
    {
      field: "phone",
      headerName: t("Phone_promocodes"),
      flex: 0.7,
    },
    {
      field: "operationType",
      headerName: t("Operation_Type"),
      flex: 0.7,
      renderCell: (params) => {
        const getOperationTypeStyle = (type) => {
          switch (type.toLowerCase()) {
            case "sign in":
              return styles.operation_type_signin;
            case "cerere card":
              return styles.operation_type_card;
            case "new_deposit":
              return styles.operation_type_new_deposit;
            case "cerere cont":
              return styles.operation_type_account;
            case "cerere credit":
              return styles.operation_type_credit;
            default:
              return styles.operation_type_default;
          }
        };

        return (
          <div
            className={`${styles.operation_type} ${getOperationTypeStyle(params.value)}`}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "successCount",
      headerName: t("Success_Count"),
      flex: 1,
    },
  ];

  const transformData = (data: PromoCodeReport[]) => {
    return data.map((row, index) => ({
      ...row,
      id: `${row.phone}_${row.promoCode}_${index}`,
    }));
  };

  const fetchData = async (exportCsv: boolean = false) => {
    try {
      setIsFetching(true);

      const queryParams = new URLSearchParams({
        beginDate: dateFrom,
        endDate: dateTo,
        useCsv: exportCsv.toString(),
        search: searchText,
        page: (currentPage + 1).toString(),
        pageSize: pageSize.toString(),
      }).toString();

      const { status, response } = await apiRequest(
        "get",
        `/admin/report/promo-codes?${queryParams}`,
        null
      );

      if (status === 200 && response.data.result === "SUCCESS") {
        if (exportCsv && response.data.reportCsv) {
          const blob = new Blob([response.data.reportCsv], {
            type: "text/csv",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "promocodes.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          const transformedData = transformData(response.data.reports || []);
          setRows(transformedData);
          setTotalCount(
            response.data.pagination?.totalCount || transformedData.length
          );
        }
      } else {
        enqueueSnackbar(t("Error fetching data"), { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      enqueueSnackbar(t("Error fetching data"), { variant: "error" });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Set up interval for periodic data fetching
    const intervalId = setInterval(() => {
      fetchData();
    }, 1220000); // 20 seconds in milliseconds

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [dateFrom, dateTo, currentPage, pageSize, searchText]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    setCurrentPage(0); // Reset to first page when searching
  };

  const selectLastMonth = () => {
    const from = dayjs().startOf("day").subtract(31, "day").format(TIME_FORMAT);
    const to = dayjs().endOf("day").format(TIME_FORMAT);
    setDateFrom(from);
    setDateTo(to);
    setPeriod(LAST_MONTH);
  };

  const selectLastThreeMonths = () => {
    const from = dayjs()
      .startOf("day")
      .subtract(3, "month")
      .format(TIME_FORMAT);
    const to = dayjs().endOf("day").format(TIME_FORMAT);
    setDateFrom(from);
    setDateTo(to);
    setPeriod(LAST_THREE_MONTHS);
  };

  const selectQuarter = () => {
    const from = dayjs().startOf("quarter").format(TIME_FORMAT);
    const to = dayjs().endOf("quarter").format(TIME_FORMAT);
    setDateFrom(from);
    setDateTo(to);
    setPeriod(QUARTER);
  };

  const handleCustomPeriodConfirm = () => {
    setDateFrom(modalDateFrom.startOf("day").format(TIME_FORMAT));
    setDateTo(modalDateTo.endOf("day").format(TIME_FORMAT));
    setPeriod(CUSTOM_PERIOD);
    setCustomPeriodDialogOpen(false);
  };

  const handlePeriodChange = (event: any) => {
    event.target.value !== CUSTOM_PERIOD && setPeriod(event.target.value);

    switch (event.target.value) {
      case LAST_MONTH:
        selectLastMonth();
        break;
      case LAST_THREE_MONTHS:
        selectLastThreeMonths();
        break;
      case QUARTER:
        selectQuarter();
        break;
      case CUSTOM_PERIOD:
        setCustomPeriodDialogOpen(true);
        break;
      default:
        selectLastMonth();
        break;
    }
  };

  const handleExportCsv = async () => {
    try {
      const queryParams = new URLSearchParams({
        beginDate: dateFrom,
        endDate: dateTo,
        useCsv: "true",
      }).toString();

      const { status, response } = await apiRequest(
        "get",
        `/admin/report/promo-codes?${queryParams}`,
        null
      );

      if (status === 200 && response.data.result === "SUCCESS") {
        // Декодируем base64 в текст
        const base64Content = atob(response.data.reportCsv);

        // Преобразуем текст в массив байтов
        const bytes = new Uint8Array(base64Content.length);
        for (let i = 0; i < base64Content.length; i++) {
          bytes[i] = base64Content.charCodeAt(i);
        }

        // Декодируем байты в текст с помощью TextDecoder
        const decoder = new TextDecoder("utf-8");
        const csvContent = decoder.decode(bytes);

        // Добавляем BOM для Excel
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

        // Создаем blob с BOM и декодированным содержимым
        const blob = new Blob([bom, csvContent], {
          type: "text/csv;charset=utf-8",
        });

        // Создаем ссылку для скачивания
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        // Формируем имя файла
        const fileName = `promocodes_${dayjs(dateFrom).format("YYYY-MM-DD")}_${dayjs(dateTo).format("YYYY-MM-DD")}.csv`;

        a.href = url;
        a.setAttribute("download", fileName);

        // Скачиваем файл
        document.body.appendChild(a);
        a.click();

        // Очищаем
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        enqueueSnackbar(t("Export_failed"), { variant: "error" });
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      enqueueSnackbar(t("Export_failed"), { variant: "error" });
    }
  };

  const paginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0); // Reset to first page when changing page size
  };

  const renderCustomPeriodDialog = () => {
    if (!isMounted) return null;

    return (
      <Dialog
        open={customPeriodDialogOpen}
        onClose={() => setCustomPeriodDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("Custom_period")}</DialogTitle>
        <DialogContent>
          <div className={styles.custom_period_content}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={language}
            >
              <div className={styles.date_picker_wrapper}>
                <label>{t("from")}:</label>
                <MuiDatePicker
                  value={modalDateFrom}
                  onChange={(newValue) => setModalDateFrom(newValue)}
                  format="DD/MM/YYYY"
                />
              </div>
              <div className={styles.date_picker_wrapper}>
                <label>{t("to")}:</label>
                <MuiDatePicker
                  value={modalDateTo}
                  onChange={(newValue) => setModalDateTo(newValue)}
                  format="DD/MM/YYYY"
                />
              </div>
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCustomPeriodConfirm}
            variant="primary"
            label={t("Apply")}
          />
          <div
            className={styles.cancel_button}
            onClick={() => setCustomPeriodDialogOpen(false)}
          >
            <span>{t("Cancel")}</span>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <AppMainLayout navBarTitle={t("Promocodes")}>
      {isMounted ? (
        <>
          {renderCustomPeriodDialog()}
          <div className={styles.container}>
            <div className={styles.filters}>
              <div className={styles.search_input_wrapper}>
                <OutlinedInput
                  startAdornment={<SearchIcon />}
                  placeholder={t("Search_placeholder_promocodes")}
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  className={styles.search_input}
                  autoComplete="off"
                />
              </div>
              <div>
                <FormControl className={styles.select_period}>
                  <CalendarIcon />
                  <label>{t("Period")}:</label>
                  <Select value={period} onChange={handlePeriodChange}>
                    <MenuItem value={LAST_MONTH}>{t("Last_month")}</MenuItem>
                    <MenuItem value={LAST_THREE_MONTHS}>
                      {t("Last_three_months")}
                    </MenuItem>
                    <MenuItem value={QUARTER}>{t("Quarter")}</MenuItem>
                    <Divider />
                    <MenuItem value={CUSTOM_PERIOD}>
                      {t("Custom_period")}
                    </MenuItem>
                  </Select>
                  {/* <div className={styles.dates}>
                  {dayjs(dateFrom).format("DD/MM/YYYY HH:mm:ss")} - {dayjs(dateTo).format("DD/MM/YYYY HH:mm:ss")}
                </div> */}
                </FormControl>
                <div
                  onClick={handleExportCsv}
                  className={styles.export_button}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleExportCsv();
                    }
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.75 15.75C20.0596 15.75 19.5 16.3096 19.5 17V19.5H4.5V17C4.5 16.3096 3.94036 15.75 3.25 15.75C2.55964 15.75 2 16.3096 2 17V19.5C2 20.875 3.125 22 4.5 22H19.5C20.875 22 22 20.875 22 19.5V17C22 16.3096 21.4404 15.75 20.75 15.75ZM17.3679 11.6321C17.8551 11.1449 17.8551 10.3551 17.3679 9.8679C16.8814 9.38139 16.0928 9.38063 15.6054 9.86619L13.25 12.2125V3.25C13.25 2.55964 12.6904 2 12 2C11.3096 2 10.75 2.55964 10.75 3.25V12.2125L8.3946 9.86619C7.90715 9.38063 7.11861 9.38139 6.6321 9.8679C6.14493 10.3551 6.14493 11.1449 6.6321 11.6321L11.8016 16.8016C11.9112 16.9112 12.0888 16.9112 12.1984 16.8016L17.3679 11.6321Z"
                      fill="white"
                    />
                  </svg>
                  <span>{t("Export_CSV")}</span>
                </div>
              </div>
            </div>
          </div>

          <DataTableComponent
            rows={rows}
            columns={columns}
            isFetching={isFetching}
            className={styles.atms_table}
            idChangeValue="id"
          />
          <div className={styles.footer_wrapper}>
            <div
              className={styles.import_button}
              role="button"
              tabIndex={0}
              onClick={() => setShowImportModal(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.75 15.75C20.0596 15.75 19.5 16.3096 19.5 17V19.5H4.5V17C4.5 16.3096 3.94036 15.75 3.25 15.75C2.55964 15.75 2 16.3096 2 17V19.5C2 20.875 3.125 22 4.5 22H19.5C20.875 22 22 20.875 22 19.5V17C22 16.3096 21.4404 15.75 20.75 15.75ZM17.3679 7.3679C17.8551 7.85507 17.8551 8.64493 17.3679 9.1321C16.8814 9.61861 16.0928 9.61937 15.6054 9.13381L13.25 6.7875V15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75V6.7875L8.3946 9.13381C7.90715 9.61937 7.11861 9.61861 6.6321 9.1321C6.14493 8.64493 6.14493 7.85507 6.6321 7.3679L11.8016 2.19841C11.9112 2.08883 12.0888 2.08883 12.1984 2.19841L17.3679 7.3679Z"
                  fill="#333333"
                />
              </svg>
              <span>{t("Import_promocodes")}</span>
            </div>

            <Pagination
              total={totalCount}
              pageSize={pageSize}
              pageIndex={currentPage}
              onClick={paginationClick}
              onChangePageSize={handlePageSizeChange}
            />
            {showImportModal && (
              <ImportModal
                onClose={() => setShowImportModal(false)}
                onSuccess={() => {
                  setShowImportModal(false);
                  fetchData();
                }}
              />
            )}
          </div>
        </>
      ) : null}
    </AppMainLayout>
  );
};

export default Promocodes;
