export const RestartIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="restart_alt">
        <g id="Vector">
          <path
            d="M12 5.29056V3.50056C12 3.05056 11.46 2.83056 11.15 3.15056L8.35 5.94056C8.15 6.14056 8.15 6.45056 8.35 6.65056L11.14 9.44056C11.46 9.75056 12 9.53056 12 9.08056V7.29056C15.31 7.29056 18 9.98056 18 13.2906C18 16.0106 16.17 18.3106 13.69 19.0406C13.27 19.1606 13 19.5606 13 19.9906C13 20.6406 13.62 21.1506 14.25 20.9606C17.57 19.9906 20 16.9306 20 13.2906C20 8.87056 16.42 5.29056 12 5.29056Z"
            fill="#333333"
          />
          <path
            d="M6 13.2906C6 11.9506 6.44 10.7106 7.19 9.70056C7.49 9.30056 7.45 8.75056 7.1 8.39056C6.68 7.97056 5.96 8.01056 5.6 8.49056C4.6 9.83056 4 11.4906 4 13.2906C4 16.9306 6.43 19.9906 9.75 20.9606C10.38 21.1506 11 20.6406 11 19.9906C11 19.5606 10.73 19.1606 10.31 19.0406C7.83 18.3106 6 16.0106 6 13.2906Z"
            fill="#333333"
          />
        </g>
      </g>
    </svg>
  );
};
