import React from "react";

export const PromocodIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2361 8.22492L17.2444 8.17492C17.3777 7.65825 16.9944 7.16659 16.4694 7.16659H14.1694L14.7527 4.84159C14.8777 4.32492 14.4944 3.83325 13.9694 3.83325C13.5944 3.83325 13.2777 4.08325 13.1861 4.44159L12.5027 7.16659H9.16939L9.75273 4.84159C9.87773 4.32492 9.49439 3.83325 8.96939 3.83325C8.59439 3.83325 8.27773 4.08325 8.18606 4.44159L7.50273 7.16659H4.80273C4.42773 7.16659 4.10273 7.41658 4.01939 7.77492L4.00273 7.82492C3.87773 8.34159 4.26106 8.83325 4.78606 8.83325H7.08606L6.25273 12.1666H3.55273C3.17773 12.1666 2.86106 12.4166 2.76939 12.7749L2.75273 12.8249C2.62773 13.3416 3.01106 13.8333 3.53606 13.8333H5.83606L5.25273 16.1583C5.12773 16.6749 5.51106 17.1666 6.03606 17.1666C6.41106 17.1666 6.72773 16.9166 6.81939 16.5583L7.50273 13.8333H10.8361L10.2527 16.1583C10.1277 16.6749 10.5111 17.1666 11.0361 17.1666C11.4111 17.1666 11.7277 16.9166 11.8194 16.5583L12.5027 13.8333H15.2027C15.5777 13.8333 15.8944 13.5833 15.9861 13.2249L15.9944 13.1749C16.1194 12.6666 15.7361 12.1666 15.2111 12.1666H12.9194L13.7527 8.83325H16.4527C16.8277 8.83325 17.1527 8.58325 17.2361 8.22492ZM11.2527 12.1666H7.91939L8.75273 8.83325H12.0861L11.2527 12.1666Z"
        fill="#A4A5B1"
      />
    </svg>
  );
};
